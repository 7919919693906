//Global functions, access anywhere with "this"
//https://stackoverflow.com/questions/42613061/vue-js-making-helper-functions-globally-available-to-single-file-components

//Reload whole page on 1 minute of inactivity

//const RGBS = ["#3366cc","#dc3912","#ff9900","#109618","#990099","#0099c6","#dd4477","#66aa00","#b82e2e","#316395","#3366cc","#994499","#22aa99","#aaaa11","#6633cc","#e67300","#8b0707","#651067","#329262","#5574a6","#3b3eac","#b77322","#16d620","#b91383","#f4359e","#9c5935","#a9c413","#2a778d","#668d1c","#bea413","#0c5922","#743411"];
const RGBS = ['#4E79A7', '#F28E2B', '#E15759', '#76B7B2', '#59A14F', '#EDC948', '#B07AA1', '#FF9DA7', '#9C755F', '#BAB0AC'] //Tableau10

export default {
  methods: {

    hex2rgb(hex) {
      let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    },

    getChartColor(index) {
      let i = index % RGBS.length;
      return this.hex2rgb(RGBS[i]);
    },

    getChartColorHex(index) {
      let i = index % RGBS.length;
      return RGBS[i];
    },

    getChartColorString(index) {
      let n = this.getChartColor(index);
      if(n==null) return "0,0,0";
      return n.r+","+n.g+","+n.b;
    },

    getChartColorStringAlpha(index,alpha) {
      let n = this.getChartColor(index);
      if(n==null) return "rgba(0,0,0,"+alpha.toFixed(2)+")";
      return "rgba("+n.r+","+n.g+","+n.b+","+alpha.toFixed(2)+")";
    },

  }
}
