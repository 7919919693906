<template>
  <div>

    <!--ABOUT-->
    <div class="container md:px-4 mx-auto mt-6 mb-6">
      <div class="p-6 md:p-10 sm:rounded-lg border-t-2 border-b-2 sm:border-2 card-bg">
        <div class="flex justify-between mb-2">
          <div class="font-bold text-gray-500 text-2xl md:text-3xl mb-5">Famous Accounts</div>
        </div>
        <div class="text-base text-gray-600 mb-2">

          <div v-for="n of accounts" v-bind:key="n.address">
            <div class="bg-gray-200 bg-opacity-50 rounded-xl h-full mb-3 md:mb-4 p-6 flex justify-between">

              <div class="flex w-full">
                <img class="rounded-full border-black border-2 border-opacity-20 h-16 md:h-24 mr-3 md:mr-4 lg:mr-6" :src="`/images/accounts/${n.address}.png`"/>
                <div class="w-full">
                  <div class="text-base md:text-xl font-bold mb-2 flex justify-between">
                    <div>
                      {{n.name}} 
                      <span v-if="n.burned>0" class="ml-1">🔥</span>
                    </div>
                    <a :href="n.url" target="_blank" class="text-blue-400"><i class="fa fa-external-link-alt ml-1"/></a>
                  </div>
                  <div class="mb-2 text-sd mc:text-base">{{n.desc}}</div>
                  <div class="text-sm text-gray-500"><b>Address:</b> <span v-html="$common.addressHtml(n.address)" class="hidden md:inline"/><span v-html="$common.shortenAddressHtml(n.address)" class="inline md:hidden"/></div>
                  <div class="text-sm text-gray-500">
                    <b>Balance:&nbsp;</b> 
                    <span v-if="n.balance>0">{{$common.formatTokenLong(n.balance)}} SHIB (<span v-html="$common.abbreviateNumberHtml(n.balance,3)"/>)</span>
                    <span v-else>None</span>
                  </div>
                  <div class="text-sm text-gray-500">
                    <b>Burned:&nbsp;</b> 
                    <span v-if="n.burned>0">{{$common.formatTokenLong(n.burned)}} SHIB (<span v-html="$common.abbreviateNumberHtml(n.burned,3)"/>)</span>
                    <span v-else class="text-gray-400">None</span>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
        <div class="text-right text-xs text-gray-400 mb-2">* if you know of any other famous accounts let us know!</div>
      </div>
    </div>
    <!--ABOUT-->


  </div>
</template>


<style lang="scss" scoped>
</style>


<script>
  import { ref } from 'vue'
  import ServerMixin from '../mixins/ServerMixin'
  import IdleReloadMixin from '../mixins/IdleReloadMixin'

  const REFRESH_SECS = (60*60) // 1 hour

  export default {

    mixins: [ServerMixin,IdleReloadMixin],

    setup() {
      let accounts = ref(null)
      return {
        accounts
      }
    },

    created() {
      document.title = "ShibReport - Famous Accounts"
    },

    async mounted() {
      await this.refreshData()
      this.idleReloadStart(REFRESH_SECS,this.refreshData)
    },

    async unmounted() {
      this.idleReloadStop()
    },
    
    methods: {

      async refreshData() {
        console.log("refreshData")
        this.$emitter.emit('spinner-on')
        this.accounts = await this.getFamousAccounts()
        this.$emitter.emit('spinner-off')
        this.idleReloadStart(REFRESH_SECS,this.refreshData)
      }

    }

  }
</script>
