<template>
  <div>

    <!-- OFFICIAL LINKS -->
    <div class="container md:px-4 mx-auto mt-6 mb-6">
      <div class="p-6 md:p-10 sm:rounded-lg border-t-2 border-b-2 sm:border-2 card-bg">
        <div class="flex justify-between">
          <div class="font-bold text-gray-500 text-2xl md:text-3xl mb-6">Useful Links</div>
        </div>
        <div class="text-base text-gray-600 mb-2">

          <!-- LINKS -->
          <div v-for="n of $store.state.OFFICIAL_LINKS" v-bind:key="n.id">
            <div class="border-l-4 border-blue-400 bg-gray-200 bg-opacity-50 rounded-xl h-full mb-3 md:mb-4 px-4 py-2 flex justify-between">
              <div class="p-1">
                <a :href="$store.methods.getFirstLink(n)" target="_blank" class="font-bold text-blue-600 underline">{{n.name}}</a> <span v-if="n.icon!=null">{{n.icon}}</span>
                <div class="text-sm text-gray-500">{{n.desc}}</div>
              </div>
              <div class="text-right">
                <a v-if="n.url!=null" :href="n.url" target="_blank" class="text-blue-400 inline-block p-1"><i class="fas fa-link"/></a>
                <a v-if="n.medium_url!=null" :href="n.medium_url" target="_blank" class="text-blue-400 inline-block p-1"><i class="fab fa-medium"/></a>
                <a v-if="n.twitter_url!=null" :href="n.twitter_url" target="_blank" class="text-blue-400 inline-block p-1"><i class="fab fa-twitter"/></a>
                <a v-if="n.discord_url!=null" :href="n.discord_url" target="_blank" class="text-blue-400 inline-block p-1"><i class="fab fa-discord"/></a>
                <a v-if="n.telegram_url!=null" :href="n.telegram_url" target="_blank" class="text-blue-400 inline-block p-1"><i class="fab fa-telegram"/></a>
              </div>
            </div>
          </div>
          <!-- LINKS -->

        </div>
      </div>
    </div>
    <!-- OFFICIAL LINKS -->



    <!-- BURN LINKS -->
    <div class="container md:px-4 mx-auto mt-6 mb-6">
      <div class="p-6 md:p-10 sm:rounded-lg border-t-2 border-b-2 sm:border-2 card-bg">
        <div class="flex justify-between">
          <div class="font-bold text-gray-500 text-2xl md:text-3xl mb-6">Burn Links</div>
        </div>
        <div class="text-base text-gray-600 mb-2">

          <!-- LINKS -->
          <div v-for="n of $store.state.BURN_LINKS" v-bind:key="n.id">
            <div class="border-l-4 border-blue-400 bg-gray-200 bg-opacity-50 rounded-xl h-full mb-3 md:mb-4 px-4 py-2 flex justify-between">
              <div class="p-1">
                <a :href="$store.methods.getFirstLink(n)" target="_blank" class="font-bold text-blue-600 underline">{{n.name}}</a> <span v-if="n.icon!=null">{{n.icon}}</span>
                <div class="text-sm text-gray-500">{{n.desc}}</div>
              </div>
              <div class="text-right">
                <a v-if="n.url!=null" :href="n.url" target="_blank" class="text-blue-400 inline-block p-1"><i class="fas fa-link"/></a>
                <a v-if="n.medium_url!=null" :href="n.medium_url" target="_blank" class="text-blue-400 inline-block p-1"><i class="fab fa-medium"/></a>
                <a v-if="n.twitter_url!=null" :href="n.twitter_url" target="_blank" class="text-blue-400 inline-block p-1"><i class="fab fa-twitter"/></a>
                <a v-if="n.discord_url!=null" :href="n.discord_url" target="_blank" class="text-blue-400 inline-block p-1"><i class="fab fa-discord"/></a>
                <a v-if="n.telegram_url!=null" :href="n.telegram_url" target="_blank" class="text-blue-400 inline-block p-1"><i class="fab fa-telegram"/></a>
              </div>
            </div>
          </div>
          <!-- LINKS -->

        </div>
      </div>
    </div>
    <!-- BURN LINKS -->
    
    
    
    <!-- BUY SHIB -->
    <div class="container md:px-4 mx-auto mt-6 mb-6">
      <div class="p-6 md:p-10 sm:rounded-lg border-t-2 border-b-2 sm:border-2 card-bg">
        <div class="flex justify-between">
          <div class="font-bold text-gray-500 text-2xl md:text-3xl mb-6">Where to Buy $SHIB</div>
        </div>
        <div class="text-base text-gray-600 mb-2">

          <!-- LINKS -->
          <a :href="$store.state.COINBASE_REFERER_URL" target="_blank">
            <div class="border-l-4 border-blue-400 bg-gray-200 bg-opacity-50 rounded-xl h-full mb-3 md:mb-4 px-4 py-2 flex justify-between">
              <div class="p-1">
                <span class="font-bold text-blue-600 underline">Coinbase.com</span>
                <div class="text-sm text-gray-500">U.S. based CEX.  Get $10 in BTC when you buy/sell $100 or more when you join.</div>
              </div>
              <div class="text-right">
                <span class="text-blue-400 inline-block p-1"><i class="fas fa-link"/></span>
              </div>
            </div>
          </a>
          <a :href="$store.state.WEBULL_REFERER_URL" target="_blank">
            <div class="border-l-4 border-blue-400 bg-gray-200 bg-opacity-50 rounded-xl h-full mb-3 md:mb-4 px-4 py-2 flex justify-between">
              <div class="p-1">
                <span class="font-bold text-blue-600 underline">WeBull.com</span>
                <div class="text-sm text-gray-500">U.S. based CEX offering crypto and stocks.  Get 2 free stocks when you join.</div>
              </div>
              <div class="text-right">
                <span class="text-blue-400 inline-block p-1"><i class="fas fa-link"/></span>
              </div>
            </div>
          </a>
          <!-- LINKS -->

        </div>
      </div>
    </div>
    <!-- BUY SHIB -->


  </div>
</template>


<style lang="scss" scoped>
</style>


<script>
  import { ref } from 'vue'

  export default {

    setup() {
    },

    created() {
      document.title = "ShibReport - Useful Links"
    },

    async mounted() {
    },
    
    methods: {
    }

  }
</script>
