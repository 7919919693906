<template>
  <div>

    <!--ABOUT-->
    <div class="container md:px-4 mx-auto mt-6 mb-6">
      <div class="p-6 md:p-10 sm:rounded-lg border-t-2 border-b-2 sm:border-2 card-bg">

        <div class="flex justify-between">
          <div class="font-bold text-gray-500 text-2xl md:text-3xl mb-10">Market Cap Switch</div>
          <div class="text-right text-xs text-gray-400">
            supply after burns = <b><span v-html="$common.abbreviateNumberHtml(shibSupply,2)"/></b><br/>
            this can change after a ton more burning
          </div>
        </div>
        
        <!-- SWITCH -->
        <div v-if="caps!=null && shibSupply!=null">
          <div class="bg-blue-50 text-blue-500 p-4 rounded-xl mb-2 md:mb-4 text-center text-xl font-bold px-6">
            What if SHIB was at the market cap of another coin?
          </div>
          <div class="grid grid-cols-2 md:grid-cols-3 grid-flow-row gap-2 md:gap-4">
            <div v-for="n of caps" v-bind:key="n.id">
              <div class=" bg-opacity-50 p-4 rounded-xl h-full" :class="n.token=='shiba-inu'?'bg-green-200':'bg-gray-200'">
                <div class="mb-2">
                  <div class="h-24 w-24 align-middle border-0 border-gray-400 border-opacity-30 mx-auto mt-3 mb-4">
                    <img class="" :src="`/images/tokens/${n.token}.png`"/>
                  </div>
                </div>
                <div class="text-center text-gray-500">
                  <div class="font-medium text-lg md:font-bold">{{n.name}}</div>
                  <div class="text-sm">Market Cap = $<span v-html="$common.abbreviateNumberHtml(n.mc,2)"/></div>
                  <div class="text-sm">Price = <span v-html="$common.formatCurrencyHtml($BN(n.price))"/></div>
                  <div class="my-3">
                    <span v-if="n.token=='shiba-inu'" class="rounded-full text-xs text-gray-600 bg-green-300 px-2 py-1">SHIB $<span class="font-bold" v-html="$common.formatTokenLong($BN(n.mc).div(shibSupply))"/></span>
                    <span v-else class="rounded-full text-xs text-gray-600 bg-yellow-200 px-2 py-1">SHIB would be $<span class="font-bold" v-html="$common.formatTokenLong($BN(n.mc).div(shibSupply))"/></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- SWITCH -->

        <!-- SWITCH -->
        <div v-if="caps!=null && shibSupply!=null" class="mt-8">
          <div class="bg-blue-50 text-blue-500 p-4 rounded-xl mb-2 md:mb-4 text-center text-xl font-bold px-6">
            How much market cap to reach these dream milestones?
          </div>
          <div class="grid grid-cols-2 grid-flow-row gap-2 md:gap-4">
            <div v-for="n of $store.state.MILESTONES" v-bind:key="n.id">
              <div class="bg-gray-200 bg-opacity-50 py-6 rounded-xl h-full">
                <div class="mb-2">
                  <div class="h-24 w-24 rounded-full overflow-hidden align-middle border-0 border-gray-400 border-opacity-30 mx-auto mb-4">
                    <img class="" :src="`/images/money/${n.short}.png`"/>
                  </div>
                </div>
                <div class="text-center text-gray-500">
                  <div class="font-medium text-lg md:font-bold">{{n.name}}</div>
                  <div class="text-sm">Market Cap = $<span v-html="$common.abbreviateNumberHtml(shibSupply*n.price,2)"/></div>
                  <div class="text-sm">Price = $<span v-html="$common.formatTokenLong($BN(n.price))"></span></div>
                  <div class="mt-3 mb-1">
                    <span class="rounded-full text-xs text-gray-600 bg-yellow-200 px-2 py-1">SHIB $<span class="font-bold" v-html="$common.formatTokenLong(n.price)"/></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- SWITCH -->
          
        <div class="text-center text-sm mt-6">
          <router-link :to="{name:'BurnGoals'}"><span class="rounded-full text-xs text-white bg-primary px-3 py-1">how long will it take to burn to 1 cent?</span></router-link>
        </div>

      </div>
    </div>
    <!--ABOUT-->

  </div>
</template>


<style lang="scss" scoped>
</style>


<script>
  import { ref } from 'vue'
  import ServerMixin from '../mixins/ServerMixin'
  import IdleReloadMixin from '../mixins/IdleReloadMixin'

  const REFRESH_SECS = (60*60) // 1 hour

  export default {

    mixins: [ServerMixin,IdleReloadMixin],

    setup() {
      const shibSupply = ref(null)
      let caps = ref(null)
      return {
        shibSupply,
        caps
      }
    },  

    created() {
      document.title = "ShibReport - Market Cap Switch Analysis"
    },

    async mounted() {
      await this.refreshData()
      this.idleReloadStart(REFRESH_SECS,this.refreshData)
    },

    async unmounted() {
      this.idleReloadStop()
    },
    
    methods: {

      async refreshData() {
        console.log("refreshData")
        this.$emitter.emit('spinner-on')
        this.caps = await this.getMarketCaps()
        let report = await this.getReport()
        this.shibSupply = this.$store.state.ONE_QUAD - report.burn_total
        this.$emitter.emit('spinner-off')
        this.idleReloadStart(REFRESH_SECS,this.refreshData)
      }

    }

  }
</script>
