import { createRouter, createWebHashHistory } from 'vue-router'
import Home from './views/Home.vue'
import Burn from './views/Burn.vue'
import About from './views/About.vue'
import Army from './views/Army.vue'
import Ecosystem from './views/Ecosystem.vue'
import Links from './views/Links.vue'
import Accounts from './views/Accounts.vue'
import Market from './views/Market.vue'
import BurnGoals from './views/BurnGoals.vue'
import Privacy from './views/Privacy.vue'
import Terms from './views/Terms.vue'
import News from './views/News.vue'
import Airdrop from './views/Airdrop.vue'


const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {path:'/',name:'Home',component:Home},
    {path:'/eco',name:'Ecosystem',component:Ecosystem},
    {path:'/burn',name:'Burn',component:Burn},
    {path:'/army',name:'Army',component:Army},
    {path:'/accounts',name:'Accounts',component:Accounts},
    {path:'/market',name:'Market',component:Market},
    {path:'/links',name:'Links',component:Links},
    {path:'/about',name:'About',component:About},
    {path:'/burn-goals',name:'BurnGoals',component:BurnGoals},
    {path:'/privacy',name:'Privacy',component:Privacy},
    {path:'/terms',name:'Terms',component:Terms},
    {path:'/news',name:'News',component:News},
    //{path:'/airdrop',name:'Airdrop',component:Airdrop},
  ]
})

// Scroll back to top on each route change
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  next()
})

export default router