<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="open=false">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-900 bg-opacity-50 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:p-6">
            <button class="outline-none" style="font-size:7em"><i class="fas fa-circle-notch fa-spin"></i></button>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
  // https://tailwindui.com/components/application-ui/overlays/modals

  import {ref} from 'vue';

  import {
    Dialog,
    DialogOverlay,
    TransitionChild, 
    TransitionRoot
  } from "@headlessui/vue";

  export default {
    
    components: {
      Dialog, DialogOverlay, TransitionChild, TransitionRoot
    },

    setup() {
      const open = ref(false)
      const counter = ref(0)
      return { open,counter }
    },

    mounted() {

      this.$emitter.on('spinner-on', params => {
        // this.counter ++;
        // if(this.counter>0) this.open = true
        this.open = true
      })

      this.$emitter.on('spinner-off', params => {
        // this.counter --;
        // if(this.counter<=0) {
        //   this.counter = 0;
        //   this.open = false
        // }
         this.open = false
      })
      
    }

  }

</script>
