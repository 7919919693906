<template>
  <div>

    <!--NEWS-->
    <div class="container md:px-4 mx-auto mt-6 mb-6">
      <div class="p-6 md:p-10 sm:rounded-lg border-t-2 border-b-2 sm:border-2 card-bg">
        
        <div class="flex justify-between mb-4">
          <div class="font-bold text-gray-500 text-2xl md:text-3xl">Shib News</div>
          <div class="text-sm text-gray-400 pb-1">
            <div class="text-right"><span class="text-red-500">Red</span>=FUD, <span class="text-green-500">Green</span>=LFG</div>
            <div v-if="tokens!=null" class="text-xs text-right">updated {{$moment.utc(tokens[0].current.created).fromNow()}}</div>
          </div>
        </div>

        <div class="text-base text-gray-600 mb-2">
          
          <!-- QUICK QUOTE -->
          <div v-if="tokens!=null" @click="toggleQuoteMode" class="cursor-pointer mt-0 md:mt-1 flex">
            <div class="bg-opacity-10 flex-1 py-2 px-2 rounded-lg text-center border-gray-200 border-2 mt-1 mb-2 text-xs sm:text-sm md:text-base">
              <div v-for="token of tokens" v-bind:key="token.store.id" class="mr-2 lg:mr-5 inline-block">
                <span class="text-gray-500 font-bold">{{token.store.short.toUpperCase()}}</span>
                <span class="ml-1" :class="token.change<0?'text-red-500':'text-green-500'">
                  <span v-if="quoteMode==0"><span v-if="token.change>0">+</span>{{token.change.toFixed(2)}}%</span>
                  <span v-else-if="quoteMode==0">${{$common.formatTokenLong($BN(token.current?.price))}}</span>
                </span>
              </div>
            </div>
          </div>
          <!-- QUICK QUOTE -->


          <!-- NEWS LIST -->
          <div v-for="n of news" v-bind:key="n.id">
            <div class="mt-0 md:mt-1 flex">
              <div class="bg-gray-400 bg-opacity-20 py-2 px-4 my-1 rounded-l-lg w-28 text-center" :class="n.fud==true?'text-red-600':'text-green-600'">
                <span class="font-mono text-sm">{{$moment(n.dt).format("MM.DD.YY")}}</span>
              </div>
              <div class="bg-gray-400 bg-opacity-10 flex-1 py-2 px-4 my-1 rounded-r-lg">
                <span>{{n.title}}</span>
                <a v-if="n.url!=null" class="text-blue-500" target="_blank" :href="n.url"><i class="fa fa-external-link-alt ml-2"/></a>
              </div>
            </div>
          </div>
          <!-- NEWS LIST -->

        </div>

        <div class="text-center text-sm mt-6">
          <router-link :to="{name:'News'}"><span class="rounded-full text-xs text-white bg-primary px-3 py-1">more ...</span></router-link>
        </div>


      </div>
    </div>
    <!--NEWS-->


    <!--TOKEN SUMMARY-->
    <div class="container md:px-4 mx-auto mt-6 mb-6">
      <div class="p-6 md:p-10 sm:rounded-lg border-t-2 border-b-2 sm:border-2 card-bg">
        <div class="flex justify-between mb-6">
          <div class="font-bold text-gray-500 text-2xl md:text-3xl">Shib Ecosystem</div>
          <div v-if="tokens!=null" class="text-right text-xs text-gray-400 flex items-end pb-1">
            <div>updated {{$moment.utc(tokens[0].current.created).fromNow()}}</div>
          </div>
        </div>
        <div v-if="tokens!=null" class="grid grid-cols-1 sm:grid-cols-2 grid-flow-row gap-2 md:gap-4 mb-4">
          <div v-for="token of tokens" v-bind:key="token.store.token">
            <token-quote :token="token"/>
          </div>
        </div>
        <div class="text-center text-sm mt-6">
          <router-link :to="{name:'Ecosystem'}"><span class="rounded-full text-xs text-white bg-primary px-3 py-1">more ...</span></router-link>
        </div>
      </div>
    </div>
    <!--TOKEN SUMMARY-->


    <!--BURNING-->
    <div class="container md:px-4 mx-auto mt-6 mb-6">
      <div class="p-6 md:p-10 sm:rounded-lg border-t-2 border-b-2 sm:border-2 card-bg">
        <div class="flex justify-between mb-6">
          <div class="font-bold text-gray-500 text-2xl md:text-3xl">Shib Burn</div>
          <div v-if="report!=null" class="text-right text-xs text-gray-400">
            supply after burns = <b><span v-html="$common.abbreviateNumberHtml($store.state.ONE_QUAD-topBurnersTotal,2)"/></b><br/>
            updated {{$moment.utc(report7.end).fromNow()}}
          </div>
        </div>

        <div class="bg-blue-50 text-blue-500 py-4 rounded-xl mb-6 font-bold flex justify-between px-4 sm:px-6">
          <div class="text-lg sm:text-xl"><span v-html="$common.abbreviateNumberHtml(topBurnersTotal,1)"/> / <span v-html="$common.abbreviateNumberHtml($store.state.ONE_QUAD,0)"/> Burned</div>
          <div class="text-lg sm:text-xl"><span v-html="$common.formatPercentHtml($BN(topBurnersTotal),$BN($store.state.ONE_QUAD),1)"/> Burned</div>
        </div>

        <div class="text-base text-gray-600 mb-2 lg:flex justify-between">
          <div class="w-full lg:w-1/2 mb-6 lg:mb-0">
            <vue3-chart-js ref="chart1" :id="burnPie1.id" :type="burnPie1.type" :data="burnPie1.data" :options="burnPie1.options"/>

            <!-- LEGEND 1 -->            
            <div v-if="burnLegend1.length>0" class="flex justify-center flex-wrap mt-2">
              <span v-for="n in burnLegend1" :key="n" class="mx-2 text-xs flex items-center">
                <span :style="{'background-color':n.color}" class="w-3 h-2 mr-1"></span><span class="whitespace-nowrap">{{n.label}}</span>
              </span>
            </div>
            <!-- LEGEND 1 -->   

          </div>
          <div class="w-full lg:w-1/2">
            <vue3-chart-js ref="chart2" :id="burnPie2.id" :type="burnPie2.type" :data="burnPie2.data" :options="burnPie2.options"/>

            <!-- LEGEND 2 -->            
            <div v-if="burnLegend2.length>0" class="flex justify-center flex-wrap mt-2">
              <span v-for="n in burnLegend2" :key="n" class="mx-2 text-xs flex items-center">
                <span :style="{'background-color':n.color}" class="w-3 h-2 mr-1"></span><span class="whitespace-nowrap">{{n.label}}</span>
              </span>
            </div>
            <!-- LEGEND 2 -->    
            
          </div>
        </div>
        <div class="w-full mt-6">
          <vue3-chart-js ref="chart3" :id="burnBar1.id" :type="burnBar1.type" :data="burnBar1.data" :options="burnBar1.options"/>
        </div>
        <div class="w-full mt-6">
          <vue3-chart-js ref="chart4" :id="burnBar2.id" :type="burnBar2.type" :data="burnBar2.data" :options="burnBar2.options"/>
        </div>

        <div class="text-center text-sm mt-6">
          <router-link :to="{name:'Burn'}"><span class="rounded-full text-xs text-white bg-primary px-3 py-1">more ...</span></router-link>
        </div>

      </div>
    </div>
    <!--BURNING-->


    <!-- ARMY -->
    <div class="container md:px-4 mx-auto mt-6 mb-6">
      <div class="p-6 md:p-10 sm:rounded-lg border-t-2 border-b-2 sm:border-2 card-bg">

        <div class="flex justify-between mb-6">
          <div class="font-bold text-gray-500 text-2xl md:text-3xl">Shib Army</div>
          <span v-if="report!=null" class="text-xs text-gray-400 text-right">
            all holders = <b>{{$common.numberCommas(report.holders)}}</b><br/>
            updated {{$moment(report7.end).fromNow()}}
          </span>
        </div>

        <div class="text-base text-gray-600 mb-2">

          <!-- RANKS -->
          <div v-if="report!=null && rankTotal!=null" class="mb-10">
            <div class="bg-blue-50 text-blue-500 py-4 rounded-xl mb-4 text-lg sm:text-xl font-bold flex justify-between px-4 sm:px-6">
              <div>Ranking</div>
              <div>{{$common.numberCommas(rankTotal)}} Troops</div>
            </div>
            <div class="grid grid-cols-2 sm:grid-cols-3 grid-flow-row gap-2 md:gap-4 mb-4">
              <div v-for="n of $store.state.RANKS" v-bind:key="n.id">
                <div class="bg-gray-200 bg-opacity-50 p-4 rounded-xl h-full">
                  <div class="mb-2">
                    <img class="h-24 w-24 rounded-full align-middle border-4 border-red-400 border-opacity-30 mx-auto mb-4 md:mb-0" :src="`/images/ranks/${n.id}.png`"/>
                  </div>
                  <div class="text-center text-gray-500">
                    <span class="font-bold text-lg">{{$common.numberCommas(report['rank_'+n.id])}}</span>
                    
                    <br class="md:hidden"/><span class="hidden md:inline">&nbsp;</span>

                    <span class="font-medium text-md md:font-bold">{{n.name}}</span><br/>
                    <span class="text-sm"><span class="hidden lg:inline">Holding&nbsp;</span>{{n.desc}}</span>
                    <div class="mt-2 mb-2">
                      <span class="rounded-full text-xs text-gray-600 bg-yellow-200 px-2 py-1"><span v-html="$common.formatPercentHtml($BN(report['rank_'+n.id]),$BN(rankTotal),3)"></span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-right text-xs text-gray-400">* holder is anyone holding >=1 token, percent shown is percent of total count</div>
          </div>
          <!-- RANKS -->

          <!-- HANDS -->
          <div v-if="report!=null && rankTotal!=null" class="mb-0">
            <div class="bg-blue-50 text-blue-500 py-4 rounded-xl mb-4 text-lg sm:text-xl font-bold flex justify-between px-4 sm:px-6">
              <div>Hand Strength</div>
              <div>{{$common.numberCommas(rankTotal)}} Holders</div>
            </div>
            <div class="grid grid-cols-2 grid-flow-row gap-2 md:gap-4 mb-4">
              <div v-for="n of $store.state.HANDS" v-bind:key="n.id">
                <div class="bg-gray-200 bg-opacity-50 p-4 rounded-xl h-full">
                  <div class="mb-2">
                    <img class="h-24 w-24 rounded-full align-middle border-4 border-gray-400 border-opacity-30 mx-auto mb-4 md:mb-0" :src="`/images/hands/${n.id}.png`"/>
                  </div>
                  <div class="text-center text-gray-500">
                    <span class="font-bold text-lg">{{$common.numberCommas(report['hand_'+n.id])}}</span>

                    <br class="md:hidden"/><span class="hidden md:inline">&nbsp;</span>

                    <span class="font-medium text-md md:font-bold">{{n.name}}</span><br/>
                    <span class="text-sm">{{n.desc}}</span>
                    <div class="mt-2 mb-2">
                      <span class="rounded-full text-xs text-gray-600 bg-yellow-200 px-2 py-1"><span v-html="$common.formatPercentHtml($BN(report['hand_'+n.id]),$BN(handTotal),1)"></span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-right text-xs text-gray-400">* holder is anyone holding >=1 token, hand is based on all-time tokens bought versus sold, percent shown is percent of total count</div>
          </div>
          <!-- HANDS -->

        </div>

        <div class="text-center text-sm mt-6">
          <router-link :to="{name:'Army'}"><span class="rounded-full text-xs text-white bg-primary px-3 py-1">more ...</span></router-link>
        </div>

      </div>
    </div>
    <!-- ARMY -->


  </div>
</template>


<style lang="scss" scoped>
</style>


<script>
  import { ref } from 'vue'
  import Vue3ChartJs from "@j-t-mcc/vue3-chartjs"
  import TokenQuote from '../components/TokenQuote'
  import IdleReloadMixin from '../mixins/IdleReloadMixin'
  import ServerMixin from '../mixins/ServerMixin'
  import ChartHelper from '../mixins/ChartHelper'
  import dataLabels from "chartjs-plugin-datalabels"

  Vue3ChartJs.registerGlobalPlugins([dataLabels])

  const REFRESH_SECS = 60

  export default {
    components: {Vue3ChartJs,TokenQuote},

    mixins: [IdleReloadMixin,ServerMixin,ChartHelper],

    setup() {
      const chart1 = ref(null)
      const chart2 = ref(null)
      const chart3 = ref(null)
      const chart4 = ref(null)
      const news = ref([])
      const report = ref()
      const report7 = ref()
      const reportMonthly = ref()
      const topBurners = ref()
      const topBurnersTotal = ref(0)
      const topHolders = ref()
      const topHoldersTotal = ref(0)
      const handTotal = ref(null)
      const rankTotal = ref(null)
      const tokens = ref()
      const quoteMode = ref(0)
      const burnLegend1 = ref([])
      const burnLegend2 = ref([])

      const burnPie1 = {
        type: "pie",
        data: {},
        options: {
          borderColor: "#d0d0d0",
          aspectRatio: 1.5,
          plugins: {
            title: {
              text: 'Supply Burned',
              align: "center",
              position: "top",
              display: true
            },
            legend: {
              position: 'bottom',
              labels: {padding: 20},
              display:false
            },
            datalabels: {
              color: 'white', 
              formatter: (value, ctx) => {
                if(value==0) return ""
                let sum = 0
                let dataArr = ctx.chart.data.datasets[0].data
                dataArr.map(data => sum += data)
                let percentage = (value*100 / sum).toFixed(1)+"%"
                return percentage
              },
              font: {weight: 'bold'}
            }
          }
        }
      }

      const burnPie2 = {
        type: "pie",
        data: {},
        options: {
          borderColor: "#e0e0e0",
          aspectRatio: 1.5,
          plugins: {
            title: {
              text: 'Other Burns',
              align: "center",
              position: "top",
              display: true
            },
            legend: {
              position: 'bottom',
              labels: {padding: 20},
              display:false
            },
            datalabels: {
              color: 'white', 
              formatter: (value, ctx) => {
                if(value==0) return ""
                let sum = 0
                let dataArr = ctx.chart.data.datasets[0].data
                dataArr.map(data => sum += data)
                let percentage = (value*100 / sum).toFixed(1)+"%"
                return percentage
              },
              font: {weight: 'bold'}
            }
          }
        }
      }
      
      const burnBar1 = {
        type: "bar",
        data: {},
        options: {
          borderWidth: 1,
          borderColor: "#d0d0d0",
          aspectRatio: 2,
          scales: {
            x: {
              reverse:true
            },
            y: {
              beginAtZero: true,
              ticks: {}
            }
          },
          plugins: {
            title: {
              text: 'Burns Last 7 Days',
              align: "bottom",
              position: "top",
              display: true
            },
            legend: {
              display:false
            },
            datalabels: {
              color: "#a0a0a0",
              anchor: "end",
              align: "end",
              font: {weight: 'bold'}
            }
          }
        }
      }
      
      const burnBar2 = {
        type: "bar",
        data: {},
        options: {
          borderWidth: 1,
          borderColor: "#d0d0d0",
          aspectRatio: 2,
          scales: {
            x: {
              reverse:true
            },
            y: {
              beginAtZero: true,
              ticks: {}
            }
          },
          plugins: {
            title: {
              text: 'Burns Last 12 Months',
              align: "bottom",
              position: "top",
              display: true
            },
            legend: {
              display:false
            },
            datalabels: {
              color: "#a0a0a0",
              anchor: "end",
              align: "end",
              font: {weight: 'bold'}
            }
          }
        }
      }

      return {
        news,
        report,report7,reportMonthly,
        burnLegend1,burnPie1,chart1,
        burnLegend2,burnPie2,chart2,
        burnBar1,burnBar2,chart3,chart4,
        topBurners,
        topBurnersTotal,
        topHolders,
        topHoldersTotal,
        rankTotal,
        handTotal,
        tokens,
        quoteMode
      }
    },

    created() {
      document.title = "ShibReport"
    },

    async mounted() {
      await this.refreshData()
      this.idleReloadStart(REFRESH_SECS,this.refreshData)
    },

    async unmounted() {
      this.idleReloadStop()
    },
    
    methods: {

      async refreshData() {
        console.log("refreshData")
        this.$emitter.emit('spinner-on')

        this.news = await this.getNews()
        this.report7 = await this.getReportRange('range','7') //Get last 7 days
        this.report = this.report7.data[0] //Current

        let burnSort = [...this.report7.data].sort((a,b)=>b.burn_day-a.burn_day)
        burnSort.forEach((n,i)=>n.sort=i)

        this.topBurners = await this.getTopBurners()
        this.topBurnersTotal = 0
        this.topBurners.forEach(n=>this.topBurnersTotal+=parseFloat(n.total))

        this.topHolders = await this.getTopHolders()
        this.topHoldersTotal = 0
        this.topHolders.forEach(n=>this.topHoldersTotal+=parseFloat(n.total))

        this.handTotal = this.report.hand_1 + this.report.hand_2 + this.report.hand_3 + this.report.hand_4
        this.rankTotal = this.report.rank_1 + this.report.rank_2 + this.report.rank_3 + this.report.rank_4 + this.report.rank_5 + this.report.rank_6 + this.report.rank_7 + this.report.rank_8 + this.report.rank_9

        //Update supply pie
        let supply = this.$store.state.ONE_QUAD - this.report.burn_total
        this.burnPie1.options.plugins.title.text = `Supply Burned ${this.$common.abbreviateNumber(this.report.burn_total,1)}`
        this.burnPie1.data.labels = [
          "Supply " + this.$common.abbreviateNumber(supply,1), 
          "Burned " + this.$common.abbreviateNumber(this.report.burn_total,1)
        ]
        this.burnPie1.data.datasets = [{
          backgroundColor: [this.getChartColorHex(4), this.getChartColorHex(2)],
          data: [supply, this.report.burn_total],
        }]
        this.burnLegend1 = []
        for(let i=0;i<this.burnPie1.data.labels.length;i++) {
          this.burnLegend1.push({
            label: this.burnPie1.data.labels[i],
            color: this.burnPie1.data.datasets[0].backgroundColor[i]
          })
        }
        this.chart1.update()

        //Update burn pie
        let totalOtherBurn = this.report.burn_shiboshi + this.report.burn_bigger + this.report.burn_other + this.report.burn_np
        this.burnPie2.options.plugins.title.text = `Other Burns ${this.$common.abbreviateNumber(totalOtherBurn,1)} sans Vitalik ${this.$common.abbreviateNumber(this.report.burn_vb,1)}`
        this.burnPie2.data.labels = [
          "Shiboshis " + this.$common.abbreviateNumber(this.report.burn_shiboshi,1),
          "Coinbase " + this.$common.abbreviateNumber(this.report.burn_bigger,1),
          "Other " + this.$common.abbreviateNumber(this.report.burn_other,1)
        ]
        this.burnPie2.data.datasets = [{
          backgroundColor: [this.getChartColorHex(1), this.getChartColorHex(6), this.getChartColorHex(3), "rgb(192,192,192)"],
          data: [this.report.burn_shiboshi,this.report.burn_bigger,this.report.burn_other+this.report.burn_np],
        }]
        this.burnLegend2 = []
        for(let i=0;i<this.burnPie2.data.labels.length;i++) {
          this.burnLegend2.push({
            label: this.burnPie2.data.labels[i],
            color: this.burnPie2.data.datasets[0].backgroundColor[i]
          })
        }
        this.chart2.update()

        // Update burn 7 day bar
        let abb = this.$common.abbreviateNumber
        this.burnBar1.data.labels = this.report7.data.map(n=>this.$moment(n.dt).format('M/D'))
        this.burnBar1.data.datasets = [{
          backgroundColor: this.report7.data.map((n,i)=>this.getChartColorStringAlpha(2,1-(n.sort/7))),
          data: this.report7.data.map(n=>n.burn_day)
        }]
        this.burnBar1.options.scales.y.ticks.callback = (value, index, values) => abb(value,1)
        this.burnBar1.options.plugins.datalabels.formatter = (value, ctx) => abb(value,1)
        this.chart3.update()

        // Update monthly burn
        this.reportMonthly = await this.getReportMonthlyRange('range','12') //Get last 12 months
        let burnSort2 = [...this.reportMonthly.data].sort((a,b)=>b.burn_day-a.burn_day)
        burnSort2.forEach((n,i)=>n.sort=i)
        this.burnBar2.data.labels = this.reportMonthly.data.map(n=>this.$moment(n.dt).format('M/YY'))
        this.burnBar2.data.datasets = [{
          backgroundColor: this.reportMonthly.data.map((n,i)=>this.getChartColorStringAlpha(2,1-(n.sort/12))),
          data: this.reportMonthly.data.map(n=>n.burn_day)
        }]
        this.burnBar2.options.scales.y.ticks.callback = (value, index, values) => abb(value,1)
        this.burnBar2.options.plugins.datalabels.formatter = (value, ctx) => abb(value,1)
        this.chart4.update()

        //Update tokens
        let tokensArr = []
        for(let n of this.$store.state.TOKENS) {
          let data = (await this.getPriceRange(n.token,'range', 2)).data
          // console.log(JSON.stringify(data))
          
          let change = data.length<=1 ? 0 : ((data[data.length-1]?.price-data[data.length-2]?.price)/data[data.length-1]?.price) * 100
          tokensArr.push({
            store: n,
            data,
            el: null,
            last: data[data.length-2],
            current: data[data.length-1],
            change
          });
        }
        this.tokens = tokensArr
        this.token = tokensArr[0]

        this.$emitter.emit('spinner-off')
        this.idleReloadStart(REFRESH_SECS,this.refreshData)
      },
      
      toggleQuoteMode() {
        if(this.quoteMode+1 > 1) this.quoteMode = 0
        else this.quoteMode ++
      }

    }

  }
</script>
