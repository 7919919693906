<template>
  <div>

    <!--BURNING-->
    <div class="container md:px-4 mx-auto mt-6 mb-6">

      <div class="p-6 md:p-10 sm:rounded-lg border-t-2 border-b-2 sm:border-2 card-bg">

        <div class="flex justify-between mb-6">
          <div class="font-bold text-gray-500 text-2xl md:text-3xl">Shib Burn</div>
          <div v-if="report!=null" class="text-right text-xs text-gray-400">
            supply after burns = <b><span v-html="$common.abbreviateNumberHtml($store.state.ONE_QUAD-topBurnersTotal,2)"/></b><br/>
            updated {{$moment.utc(report7.end).fromNow()}}
          </div>
        </div>

        <div class="bg-blue-50 text-blue-500 py-4 rounded-xl mb-6 font-bold flex justify-between px-4 sm:px-6">
          <div class="text-lg sm:text-xl"><span v-html="$common.abbreviateNumberHtml(topBurnersTotal,1)"/> / <span v-html="$common.abbreviateNumberHtml($store.state.ONE_QUAD,0)"/> Burned</div>
          <div class="text-lg sm:text-xl"><span v-html="$common.formatPercentHtml($BN(topBurnersTotal),$BN($store.state.ONE_QUAD),1)"/> Burned</div>
        </div>

        <div class="text-base text-gray-600 mb-2 lg:flex justify-between">
          <div class="w-full lg:w-1/2 mb-6 lg:mb-0">
            <vue3-chart-js ref="chart1" :id="burnPie1.id" :type="burnPie1.type" :data="burnPie1.data" :options="burnPie1.options"/>

            <!-- LEGEND 1 -->            
            <div v-if="burnLegend1.length>0" class="flex justify-center flex-wrap mt-2">
              <span v-for="n in burnLegend1" :key="n" class="mx-2 text-xs flex items-center">
                <span :style="{'background-color':n.color}" class="w-3 h-2 mr-1"></span><span class="whitespace-nowrap">{{n.label}}</span>
              </span>
            </div>
            <!-- LEGEND 1 -->   
            
          </div>
          <div class="w-full lg:w-1/2">
            <vue3-chart-js ref="chart2" :id="burnPie2.id" :type="burnPie2.type" :data="burnPie2.data" :options="burnPie2.options"/>
  
            <!-- LEGEND 2 -->            
            <div v-if="burnLegend2.length>0" class="flex justify-center flex-wrap mt-2">
              <span v-for="n in burnLegend2" :key="n" class="mx-2 text-xs flex items-center">
                <span :style="{'background-color':n.color}" class="w-3 h-2 mr-1"></span><span class="whitespace-nowrap">{{n.label}}</span>
              </span>
            </div>
            <!-- LEGEND 2 -->            

          </div>
        </div>
        <div class="w-full mt-6">
          <vue3-chart-js ref="chart3" :id="burnBar1.id" :type="burnBar1.type" :data="burnBar1.data" :options="burnBar1.options"/>
        </div>
        <div class="w-full mt-6">
          <vue3-chart-js ref="chart4" :id="burnBar2.id" :type="burnBar2.type" :data="burnBar2.data" :options="burnBar2.options"/>
        </div>

        <!-- TOP BURNERS -->
        <div v-if="topBurners!=null">
          <div class="block w-full overflow-x-auto mt-10 text-gray-600 border-2 border-gray-200 border-opacity-50 rounded-lg">
            <table class="items-center bg-transparent w-full border-collapse">
              <thead>
                <tr>
                  <th class="px-5 bg-blue-50 text-blue-500 align-middle border border-solid border-blue-100 py-3 text-xs uppercase border-l-0 border-r-0 border-t-0 whitespace-nowrap font-semibold text-left" width="60%">
                    Top {{$store.state.TOP_LIST_SIZE}} Burners <i class="fas fa-fire"></i>
                  </th>
                  <th class="px-5 bg-blue-50 text-blue-500 align-middle border border-solid border-blue-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Last Burn
                  </th>
                  <th class="px-5 bg-blue-50 text-blue-500 align-middle border border-solid border-blue-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-right">
                    Burned
                  </th>
                  <th class="px-5 bg-blue-50 text-blue-500 align-middle border border-solid border-blue-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-right">
                    %
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(n,i) of topBurners" v-bind:key="i">
                  <td class="border-t-0 px-5 align-middle border-l-0 border-r-0 whitespace-nowrap p-3 text-left text-sm">
                    <span v-if="n.name!=null" :title="n.address" class="font-bold">{{n.name}}</span>
                    <span v-else v-html="$common.addressHtml(n.address)"></span>
                  </td>
                  <td class="border-t-0 px-5 align-center border-l-0 border-r-0 whitespace-nowrap p-3 text-sm">
                    {{$moment(n.dt).fromNow()}}
                  </td>
                  <td class="border-t-0 px-5 align-middle border-l-0 border-r-0 whitespace-nowrap p-3 text-right text-sm">
                    <span v-html="$common.abbreviateNumberHtml(parseFloat(n.total),1)"/>
                  </td>
                  <td class="border-t-0 px-5 align-middle border-l-0 border-r-0 whitespace-nowrap p-3 text-right text-sm">
                    <span v-html="$common.formatPercentHtml($BN(n.total),$BN(topBurnersTotal),3)"/>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-right text-xs text-gray-400 mt-4">* percent is % of all tokens burned</div>
        </div>
        <!-- TOP BURNERS -->

        <!-- RECENT BURNS -->
        <div v-if="recentBurns!=null">
          <div class="block w-full overflow-x-auto mt-10 text-gray-600 border-2 border-gray-200 border-opacity-50 rounded-lg">
            <table class="items-center bg-transparent w-full border-collapse">
              <thead>
                <tr>
                  <th class="px-5 bg-blue-50 text-blue-500 align-middle border border-solid border-blue-100 py-3 text-xs uppercase border-l-0 border-r-0 border-t-0 whitespace-nowrap font-semibold text-left" width="60%">
                    Recent {{$store.state.RECENT_LIST_SIZE}} Burns <i class="fas fa-fire"></i>
                  </th>
                  <th class="px-5 bg-blue-50 text-blue-500 align-middle border border-solid border-blue-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Last Burn
                  </th>
                  <th class="px-5 bg-blue-50 text-blue-500 align-middle border border-solid border-blue-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-right">
                    Burned
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(n,i) of recentBurns" v-bind:key="i">
                  <td class="border-t-0 px-5 align-middle border-l-0 border-r-0 whitespace-nowrap p-3 text-left text-sm">
                    <span v-if="n.name!=null" :title="n.address" class="font-bold">{{n.name}}</span>
                    <span v-else v-html="$common.addressHtml(n.address)"></span>
                  </td>
                  <td class="border-t-0 px-5 align-center border-l-0 border-r-0 whitespace-nowrap p-3 text-sm">
                    {{$moment(n.dt).fromNow()}}
                  </td>
                  <td class="border-t-0 px-5 align-middle border-l-0 border-r-0 whitespace-nowrap p-3 text-right text-sm">
                    <span v-html="$common.abbreviateNumberHtml(parseFloat(n.total),1)"/>
                    <a v-if="n.tx!=null" :href="'https://etherscan.io/tx/'+n.tx" target="_blank" class="text-blue-400"><i class="fa fa-external-link-alt ml-1"/></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-center text-sm mt-6">
            <router-link :to="{name:'BurnGoals'}"><span class="rounded-full text-xs text-white bg-primary px-3 py-1">how long will it take to burn to 1 cent?</span></router-link>
          </div>
        </div>
        <!-- RECENT BURNS -->

      </div>
    </div>
    <!--BURNING-->


    <!--FAQ-->
    <div class="container md:px-4 mx-auto mt-6 mb-6">
      <div class="p-6 md:p-10 sm:rounded-lg border-t-2 border-b-2 sm:border-2 card-bg">
        <div class="flex justify-between">
          <div class="font-bold text-gray-500 text-2xl md:text-3xl">Burn FAQ</div>
        </div>

        <div class="text-base mt-4">
          <div class="my-5 border-2 border-opacity-50 p-4 md:p-6 rounded-lg text-md">
            <div class="font-bold mb-2 text-gray-500">How does one "burn" tokens?</div>
            <div class="text-gray-600">
              To burns tokens, simply send them to the "burn wallet" at <span v-html="$common.shortenAddressHtml($store.state.BURN_ADDRESS)"/>.  
              Once there it is considered "burned" and cannot be recovered, no one "should" have access to it.
              The less tokens in circulations, the higher the value of $SHIB will go based go based on its market cap.
            </div>
          </div>
        </div>

        <div class="text-base mt-4">
          <div class="my-5 border-2 border-opacity-50 p-4 md:p-6 rounded-lg text-md">
            <div class="font-bold mb-2 text-gray-500">Why the heck would I burn my tokens, you think I'm stupid?</div>
            <div class="text-gray-600">
              If you really believe in SHIB and are willing to "take one for the team" you can burn your tokens to lose some money, while making everyone else's token value go up.
              There are a few non-sacrificial ways of doing this, one is by renaming your <a href="https://shiboshis.shibaswap.com/#/" target="_blank" class="text-blue-500 underline">Shiboshi NFTs</a> if you own any.
              The other is listening to some smooth beats by <a href="https://https://www.biggerentertainment.com/shib" target="_blank" class="text-blue-500 underline">Bigger Entertainment</a>.
            </div>
          </div>
        </div>

      </div>
    </div>
    <!--FAQ-->


  </div>
</template>


<style lang="scss" scoped>
</style>


<script>
  import { ref } from 'vue'
  import IdleReloadMixin from '../mixins/IdleReloadMixin'
  import ServerMixin from '../mixins/ServerMixin'
  import Vue3ChartJs from "@j-t-mcc/vue3-chartjs"
  import ChartHelper from '../mixins/ChartHelper'
  import dataLabels from "chartjs-plugin-datalabels"

  Vue3ChartJs.registerGlobalPlugins([dataLabels])

  const REFRESH_SECS = 60

  export default {
    components: {Vue3ChartJs},

    mixins: [IdleReloadMixin,ServerMixin,ChartHelper],

    setup() {
      const chart1 = ref(null)
      const chart2 = ref(null)
      const chart3 = ref(null)
      const chart4 = ref(null)
      const report = ref()
      const report7 = ref()
      const reportMonthly = ref()
      const recentBurns = ref()
      const topBurners = ref()
      const topBurnersTotal = ref(0)
      const topHolders = ref()
      const topHoldersTotal = ref(0)
      const handTotal = ref(null)
      const rankTotal = ref(null)
      const burnLegend1 = ref([])
      const burnLegend2 = ref([])
      
      const burnPie1 = {
        type: "pie",
        data: {},
        options: {
          borderColor: "#d0d0d0",
          aspectRatio: 1.5,
          plugins: {
            title: {
              text: 'Supply Burned',
              align: "center",
              position: "top",
              display: true
            },
            legend: {
              position: 'bottom',
              labels: {padding: 20},
              display: false
            },
            datalabels: {
              color: 'white', 
              formatter: (value, ctx) => {
                if(value==0) return ""
                let sum = 0
                let dataArr = ctx.chart.data.datasets[0].data
                dataArr.map(data => sum += data)
                let percentage = (value*100 / sum).toFixed(1)+"%"
                return percentage
              },
              font: {weight: 'bold'}
            }
          }
        }
      }

      const burnPie2 = {
        type: "pie",
        data: {},
        options: {
          borderColor: "#e0e0e0",
          aspectRatio: 1.5,
          plugins: {
            title: {
              text: 'Other Burns',
              align: "center",
              position: "top",
              display: true
            },
            legend: {
              position: 'bottom',
              labels: {padding: 20},
              display: false
            },
            datalabels: {
              color: 'white', 
              formatter: (value, ctx) => {
                if(value==0) return ""
                let sum = 0
                let dataArr = ctx.chart.data.datasets[0].data
                dataArr.map(data => sum += data)
                let percentage = (value*100 / sum).toFixed(1)+"%"
                return percentage
              },
              font: {weight: 'bold'}
            }
          }
        }
      }
      
      const burnBar1 = {
        type: "bar",
        data: {},
        options: {
          borderWidth: 1,
          borderColor: "#d0d0d0",
          aspectRatio: 2,
          scales: {
            x: {
              reverse:true
            },
            y: {
              beginAtZero: true,
              offset: true,
              ticks: {}
            }
          },
          plugins: {
            title: {
              text: 'Burns Last 7 Days',
              align: "bottom",
              position: "top",
              display: true
            },
            legend: {
              display:false
            },
            datalabels: {
              color: "#a0a0a0",
              anchor: "end",
              align: "end",
              font: {weight: 'bold'}
            }
          }
        }
      }

      const burnBar2 = {
        type: "bar",
        data: {},
        options: {
          borderWidth: 1,
          borderColor: "#d0d0d0",
          aspectRatio: 2,
          scales: {
            x: {
              reverse:true
            },
            y: {
              beginAtZero: true,
              ticks: {}
            }
          },
          plugins: {
            title: {
              text: 'Burns Last 12 Months',
              align: "bottom",
              position: "top",
              display: true
            },
            legend: {
              display:false
            },
            datalabels: {
              color: "#a0a0a0",
              anchor: "end",
              align: "end",
              font: {weight: 'bold'}
            }
          }
        }
      }

      return {
        report,report7,reportMonthly,
        burnLegend1,burnPie1,chart1,
        burnLegend2,burnPie2,chart2,
        burnBar1,burnBar2,chart3,chart4,
        topBurners,
        topBurnersTotal,
        recentBurns,
        topHolders,
        topHoldersTotal,
        rankTotal,
        handTotal
      }
    },

    created() {
      document.title = "ShibReport - Shib Burn Analysis"
    },

    async mounted() {
      await this.refreshData()
      this.idleReloadStart(REFRESH_SECS,this.refreshData)
    },

    async unmounted() {
      this.idleReloadStop()
    },
    
    methods: {

      async refreshData() {
        console.log("refreshData")
        this.$emitter.emit('spinner-on')

        this.report7 = await this.getReportRange('range','7') //Get last 7 days
        this.report = this.report7.data[0] //Current!

        //console.log(JSON.stringify(this.report))

        let burnSort = [...this.report7.data].sort((a,b)=>b.burn_day-a.burn_day)
        burnSort.forEach((n,i)=>n.sort=i)
    
        this.recentBurns = await this.getRecentBurns()

        this.topBurners = await this.getTopBurners()
        this.topBurnersTotal = 0
        this.topBurners.forEach(n=>this.topBurnersTotal+=parseFloat(n.total))

        this.topHolders = await this.getTopHolders()
        this.topHoldersTotal = 0
        this.topHolders.forEach(n=>this.topHoldersTotal+=parseFloat(n.total))

        this.handTotal = this.report.hand_1 + this.report.hand_2 + this.report.hand_3 + this.report.hand_4
        this.rankTotal = this.report.rank_1 + this.report.rank_2 + this.report.rank_3 + this.report.rank_4 + this.report.rank_5 + this.report.rank_6 + this.report.rank_7 + this.report.rank_8 + this.report.rank_9

        //Update supply pie
        let supply = 1000000000000000 - this.report.burn_total
        this.burnPie1.options.plugins.title.text = `Supply Burned ${this.$common.abbreviateNumber(this.report.burn_total,1)}`
        this.burnPie1.data.labels = [
          "Supply " + this.$common.abbreviateNumber(supply,1), 
          "Burned " + this.$common.abbreviateNumber(this.report.burn_total,1)
        ]
        this.burnPie1.data.datasets = [{
          backgroundColor: [this.getChartColorHex(4), this.getChartColorHex(2)],
          data: [supply, this.report.burn_total],
        }]
        this.burnLegend1 = []
        for(let i=0;i<this.burnPie1.data.labels.length;i++) {
          this.burnLegend1.push({
            label: this.burnPie1.data.labels[i],
            color: this.burnPie1.data.datasets[0].backgroundColor[i]
          })
        }
        this.chart1.update()

        //Update burn pie
        let totalOtherBurn = this.report.burn_shiboshi + this.report.burn_bigger + this.report.burn_other + this.report.burn_np
        this.burnPie2.options.plugins.title.text = `Other Burns ${this.$common.abbreviateNumber(totalOtherBurn,1)} sans Vitalik ${this.$common.abbreviateNumber(this.report.burn_vb,1)}`
        this.burnPie2.data.labels = [
          "Shiboshis " + this.$common.abbreviateNumber(this.report.burn_shiboshi,1),
          "Coinbase User " + this.$common.abbreviateNumber(this.report.burn_bigger,1),
          "Other " + this.$common.abbreviateNumber(this.report.burn_other,1)
        ]
        this.burnPie2.data.datasets = [{
          backgroundColor: [this.getChartColorHex(1), this.getChartColorHex(6), this.getChartColorHex(3), "rgb(192,192,192)"],
          data: [this.report.burn_shiboshi,this.report.burn_bigger,this.report.burn_np+this.report.burn_other],
        }]
        this.burnLegend2 = []
        for(let i=0;i<this.burnPie2.data.labels.length;i++) {
          this.burnLegend2.push({
            label: this.burnPie2.data.labels[i],
            color: this.burnPie2.data.datasets[0].backgroundColor[i]
          })
        }
        this.chart2.update()

        // Update burn 7 day bar
        let abb = this.$common.abbreviateNumber
        this.burnBar1.data.labels = this.report7.data.map(n=>this.$moment(n.dt).format('M/D'))
        this.burnBar1.data.datasets = [{
          backgroundColor: this.report7.data.map((n,i)=>this.getChartColorStringAlpha(2,1-(n.sort/7))),
          data: this.report7.data.map(n=>n.burn_day)
        }]
        this.burnBar1.options.scales.y.ticks.callback = (value, index, values) => abb(value,1)
        this.burnBar1.options.plugins.datalabels.formatter = (value, ctx) => abb(value,1)
        this.chart3.update()


        // Update monthly burn
        this.reportMonthly = await this.getReportMonthlyRange('range','12') //Get last 12 months
        let burnSort2 = [...this.reportMonthly.data].sort((a,b)=>b.burn_day-a.burn_day)
        burnSort2.forEach((n,i)=>n.sort=i)
        this.burnBar2.data.labels = this.reportMonthly.data.map(n=>this.$moment(n.dt).format('M/YY'))
        this.burnBar2.data.datasets = [{
          backgroundColor: this.reportMonthly.data.map((n,i)=>this.getChartColorStringAlpha(2,1-(n.sort/12))),
          data: this.reportMonthly.data.map(n=>n.burn_day)
        }]
        this.burnBar2.options.scales.y.ticks.callback = (value, index, values) => abb(value,1)
        this.burnBar2.options.plugins.datalabels.formatter = (value, ctx) => abb(value,1)
        this.chart4.update()

        this.$emitter.emit('spinner-off')
        this.idleReloadStart(REFRESH_SECS,this.refreshData)
      }
      
    },
  }
</script>
