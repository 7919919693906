import $ from 'jquery'

const SHIBREPORT_API_KEY = 'abc123'
const EXPIRE_QUICK = 60 //1 min
const EXPIRE_SLOW = 60 * 30 //30 mins
const EXPIRE_HOUR = 60 * 60 //1 hour
const EXPIRE_DAY = 60 * 60 * 24 //1 day

export default {
  methods: {

    getApiData(urlPart,expireSecs=EXPIRE_QUICK) {
      let now = new Date()
      let url = `${this.$store.state.server_url}/api/${urlPart}`;

      return new Promise((resolve,reject)=>{

        // Check the time if we should reload this
        let ok = false
        if(this.$store.state.api_data[url]==null) ok = true
        else {
          let diffSecs = Math.floor((now - this.$store.state.api_data[url].dt) / 1000)
          if(diffSecs > expireSecs) ok = true
          else { //Return existing data
            this.$store.state.api_data[url].last = now // Keep track of last access in case we want to free it
            resolve(this.$store.state.api_data[url].result)
            return
          }
        }
        
        // Reload from server
        if(ok) {
          $.ajax({url,headers:{'shibreport-api-key':SHIBREPORT_API_KEY}}).done(response=> {

            if(response.ok==0) {
              //console.log(`>>> getApiData(${url}) ERR#1: ${response.err.message}`)

              this.$emitter.emit('notification',{variant:'danger',message:"Could not connect please reload."})
              this.$emitter.emit('spinner-off')

              if(this.$store.state.api_data[url]!=null && this.$store.state.api_data[url].result!=null) resolve(this.$store.state.api_data[url].result) //Allow safe failure
              else reject(Error(response.err.message))
              return
            }

            // Check for bad data, return last
            if(Array.isArray(response.result) && response.result.length==0) return resolve(this.$store.state.api_data[url].result) // Empty array
            else if(typeof response.result === "object" && Object.keys(response.result).length==0) return resolve(this.$store.state.api_data[url].result) // Empty object
            else if(response.result == null) return resolve(this.$store.state.api_data[url].result) // Null

            // Data is good, cache it and return it
            this.$store.state.api_data[url]={dt:now,last:now,result:response.result}
            return resolve(response.result)

          }).fail((jqXHR,textStatus,error)=>{
            //console.log(`>>> getApiData(${url}) ERR#2: ${error}`)

            this.$emitter.emit('notification',{variant:'danger',message:"Could not connect please reload."})
            this.$emitter.emit('spinner-off')

            reject(error)
          });
        }
        else resolve(null)        

      })
    },
    
    getConfig() {
      return this.getApiData(`v1/config`,EXPIRE_QUICK)
    },

    getNews() {
      return this.getApiData(`v1/news`,EXPIRE_SLOW)
    },

    getAlerts() {
      return this.getApiData(`v1/alerts`,EXPIRE_SLOW)
    },

    getNewsFull() {
      return this.getApiData(`v1/news/full`,EXPIRE_SLOW)
    },

    getTopHolders() {
      return this.getApiData(`v1/top/holders`,EXPIRE_HOUR)
    },

    getTopBurners() {
      return this.getApiData(`v1/top/burners`,EXPIRE_DAY)
    },
    
    getRecentBurns() {
      return this.getApiData(`v1/recent/burns`,EXPIRE_HOUR)
    },

    getBalance(address) {
      return this.getApiData(`v1/balance/${address}`,EXPIRE_QUICK)
    },

    getBurned(address) {
      return this.getApiData(`v1/burned/${address}`,EXPIRE_QUICK)
    },

    getFamousAccounts(address) {
      return this.getApiData(`v1/famous-accounts`,EXPIRE_QUICK)
    },

    getReport() {
      return this.getApiData(`v1/report`,EXPIRE_HOUR)
    },

    getReportRange(list,type) { //daily,monthly
      return this.getApiData(`v1/report/${list}/${type}`,EXPIRE_HOUR)
    },

    getReportMonthly() {
      return this.getApiData(`v1/report-monthly`,EXPIRE_HOUR)
    },

    getReportMonthlyRange(list,type) { //daily,monthly
      return this.getApiData(`v1/report-monthly/${list}/${type}`,EXPIRE_HOUR)
    },

    getRank() {
      return this.getApiData(`v1/rank`,EXPIRE_HOUR)
    },

    getRankRange(list,type) { //daily,monthly
      return this.getApiData(`v1/rank/${list}/${type}`,EXPIRE_HOUR)
    },

    getHand() {
      return this.getApiData(`v1/hand`,EXPIRE_HOUR)
    },

    getHandRange(list,type) { //daily,monthly
      return this.getApiData(`v1/hand/${list}/${type}`,EXPIRE_HOUR)
    },

    getPrice(token) {
      return this.getApiData(`v1/price/${token}`,EXPIRE_QUICK)
    },

    getMarketCaps() {
      return this.getApiData(`v1/caps`,EXPIRE_DAY)
    },

    getBurnGoals() {
      return this.getApiData(`v1/burn/goals`,EXPIRE_DAY)
    },

    getPriceRange(token,list,type) { //daily,monthly
      return this.getApiData(`v1/price/${token}/${list}/${type}`,EXPIRE_QUICK)
    },

    getAirdrop() {
      return this.getApiData(`v1/airdrop`,EXPIRE_DAY)
    },

  }
}
