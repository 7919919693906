<template>
  <div>

    <!--ABOUT-->
    <div class="container md:px-4 mx-auto mt-6 mb-6">
      <div class="p-6 md:p-10 sm:rounded-lg border-t-2 border-b-2 sm:border-2 card-bg">
        <div class="flex justify-between">
          <div class="font-bold text-gray-500 text-2xl md:text-3xl mb-5">About Us</div>
        </div>
        <div class="text-base text-gray-600 mb-2">
          <p class="mb-4">
            Thanks for visiting us at ShibReport.  
            We are a team of blockchain developers and designers trying to make the most comprehensive on-chain analytics and reporting website (and fun stats) for the #ShibArmy.
            <!--We also offer <a :href="$store.state.TWITTER_URL" target="_blank" class="underline text-blue-600">Twitter alerts</a> that will tweet when big SHIB events happen like BIG BURNS, BIG MOVES and the DAILY SHIB, so be sure to follow us!-->
            Like many of you in the Shib Army, we dream of $SHIB launching to 1 cent or more!  Together we can make it happen!
          </p>
          <p>
            We strive to keep the accuracy of our on-chain data.
            The site usually updates automatically everyday.
            If you see any errors on the site please let us know and we will get back to you within 24 hours.
            If you have the means, and you enjoy our site, please donate $SHIB or any ERC20 token to our address to keep our services up and running.
          </p>
        </div>

        <div class="block mt-8">

          <div class="hidden md:inline-block font-bold bg-gray-400 border-2 border-gray-500 text-white px-3 py-1 rounded-full inline-block">
            <div class="flex items-center justify-center text-sm md:text-base">
              <span>DONATE {{$store.state.DONATION_ADDRESS}}</span> 
              <i class="fas fa-heart ml-2"/>
            </div>
          </div>
          <div class="inline-block md:hidden font-bold bg-gray-400 border-2 border-gray-500 text-white px-3 py-1 rounded-full inline-block cursor-pointer" @click="donateClicked">
            <div class="flex items-center justify-center text-sm md:text-base">
              <span>DONATE</span> 
              <i class="fas fa-heart ml-2"/>
            </div>
          </div>

        </div>

        <!--
        <a :href="$store.state.TWITTER_URL" target="_blank" class="block mt-4">
          <button class="font-bold bg-blue-400 hover:bg-blue-300 border-2 border-blue-500 text-white px-3 py-1 rounded-full">
            <div class="flex items-center justify-center text-sm md:text-base">
              <span>VISIT TWITTER</span> 
              <i class="fab fa-twitter ml-2"/>
            </div>
          </button>
        </a>
        -->
        
        <a :href="'mailto:'+$store.state.CONTACT_EMAIL" class="block mt-4">
          <button class="font-bold bg-primary hover:bg-primary-light border-2 border-primary-dark text-white px-3 py-1 rounded-full">
            <div class="flex items-center justify-center text-sm md:text-base">
              <span>CONTACT US</span> 
              <i class="fa fa-envelope ml-2"/>
            </div>
          </button>
        </a>

        <div class="mb-1"></div>
      </div>
    </div>
    <!--ABOUT-->


    <!--FAQ-->
    <div class="container md:px-4 mx-auto mt-6 mb-6">
      <div class="p-6 md:p-10 sm:rounded-lg border-t-2 border-b-2 sm:border-2 card-bg">
        <div class="flex justify-between">
          <div class="font-bold text-gray-500 text-2xl md:text-3xl">FAQ</div>
        </div>

        <div class="text-base mt-4">
          <div class="my-5 border-2 border-opacity-50 p-4 md:p-6 rounded-lg text-md">
            <div class="font-bold mb-2 text-gray-500">How can I trust anything on this site?</div>
            <div class="text-gray-600">
              Trust me bro.
              This site is for entertainment purposes only, it is definitely NOT financial advice.
              Although we strive to get the most accurate data for SHIB, it is possible we have made mistakes.
              Please do your own due diligence and double check everything before you make any financial decisions.
            </div>
          </div>
        </div>

        <div class="text-base mt-4">
          <div class="my-5 border-2 border-opacity-50 p-4 md:p-6 rounded-lg text-md">
            <div class="font-bold mb-2 text-gray-500">I see something wrong on the website, how can it be fixed?</div>
            <div class="text-gray-600">
              If you see any calculation issues please let us know by <a :href="'mailto:'+$store.state.CONTACT_EMAIL" class="text-blue-500 underline">emailing us</a> we will get back to you within 24 hours.
              We want to be as accurate as possible!
            </div>
          </div>
        </div>

        <div class="text-base mt-4">
          <div class="my-5 border-2 border-opacity-50 p-4 md:p-6 rounded-lg text-md">
            <div class="font-bold mb-2 text-gray-500">How can I reach you for a business partnership or advertisement?</div>
            <div class="text-gray-600">
              If you have any proposals please let us know by <a :href="'mailto:'+$store.state.CONTACT_EMAIL" class="text-blue-500 underline">emailing us</a> we want to expand our business.
              When traffic is higher we will start accepting advertisements.  Serious business proposals only.
            </div>
          </div>
        </div>

        <div class="text-base mt-4">
          <div class="my-5 border-2 border-opacity-50 p-4 md:p-6 rounded-lg text-md">
            <div class="font-bold mb-2 text-gray-500">Will $SHIB ever reach the 1 cent goal?</div>
            <div class="text-gray-600">
              It will be very difficult, but anything is possible.  As long as the SHIB developers keep working on Ryoshi's vision, especially the Layer 2 solution called Shibarium.  
              Other factors like more burning mechanisms, more listings in other countries and CEXs, and overall increase in the market cap of all crypto.  
              Check out our <router-link :to="{name:'Market'}" class="text-blue-500 underline">Market Cap Switch</router-link> page for fun what-if scenarios.
            </div>
          </div>
        </div>


        <div class="text-base mt-4">
          <div class="my-5 border-2 border-opacity-50 p-4 md:p-6 rounded-lg text-md">
            <div class="font-bold mb-2 text-gray-500">Where are your privacy policy and terms of service?</div>
            <div class="text-gray-600">
              Please see our <router-link :to="{name:'Privacy'}" class="text-blue-500 underline">privacy policy</router-link> and <router-link :to="{name:'Terms'}" class="text-blue-500 underline">terms of service</router-link>.
            </div>
          </div>
        </div>

      </div>
    </div>
    <!--FAQ-->


  </div>
</template>


<style lang="scss" scoped>
</style>


<script>
  export default {

    setup() {
    },

    async created() {
      document.title = "ShibReport - About Us"
    },

    async mounted() {
    },
    
    methods: {

      donateClicked() {
        this.$emitter.emit('ok-modal',{title:'Donate to ShibReport',message:`<span class="text-xs">${this.$store.state.DONATION_ADDRESS}</span>`})
      }

    }

  }
</script>
