<template>
  <div>
    <Spinner/>
    <OkModal/>
    <ConfirmModal/>
    <ImageModal/>
    <Notification/>

    <div class="h-full">

      <!-- LOGO -->  
      <router-link :to="{name:'Home'}" class="w-full md:mb-1">
        <img src="images/web-banner.png" class="mx-auto w-2/4 md:w-2/5 max-w-md mt-3 mb-5"/>
      </router-link>
      <!-- LOGO -->  

      <!--Nav-->
      <div class="container w-full md:w-4/5 mx-auto pb-4">
        <div class="flex items-center justify-between sm:mx-10 py-2 px-4 sm:rounded-full md:border-2 border-t-2 border-b-2 card-bg">
          
          <div class="flex items-center mx-1">          
            <router-link :to="{name:'Home'}" :class="$route.name=='Home'?'bg-opacity-80 hover:bg-opacity-90 text-white':'bg-opacity-5 hover:bg-opacity-10 text-gray-600'" class="no-underline hover:no-underline mr-2 md:mr-3 px-3 py-1 rounded-full bg-primary-dark text-sm">
              <i class="fa fa-home"/>
            </router-link>
            <router-link :to="{name:'Army'}" :class="$route.name=='Army'?'bg-opacity-80 hover:bg-opacity-90 text-white':'bg-opacity-5 hover:bg-opacity-10 text-gray-600'" class="no-underline hover:no-underline mr-2 md:mr-3 px-3 py-1 rounded-full bg-primary-dark text-sm">
              ARMY
            </router-link>
            <router-link :to="{name:'Burn'}" :class="$route.name=='Burn'?'bg-opacity-80 hover:bg-opacity-90 text-white':'bg-opacity-5 hover:bg-opacity-10 text-gray-600'" class="no-underline hover:no-underline mr-2 md:mr-3 px-3 py-1 rounded-full bg-primary-dark text-sm">
              BURN
            </router-link>
            <router-link :to="{name:'Ecosystem'}" :class="$route.name=='Ecosystem'?'bg-opacity-80 hover:bg-opacity-90 text-white':'bg-opacity-5 hover:bg-opacity-10 text-gray-600'" class="no-underline hover:no-underline mr-2 md:mr-3 px-3 py-1 rounded-full bg-primary-dark text-sm">
              ECO
            </router-link>
          </div>

          <Menu as="div" class="relative inline-block text-left">
            <MenuButton :class="($route.name=='Market'||$route.name=='Accounts'||$route.name=='Links'||$route.name=='About'||$route.name=='BurnGoals')?'bg-opacity-80 hover:bg-opacity-90 text-white':'bg-opacity-5 hover:bg-opacity-10 text-gray-600'" class="dropbtn bg-opacity-5 hover:bg-opacity-10 no-underline hover:no-underline mr-2 md:mr-3 px-3 py-1 rounded-full bg-primary-dark text-sm">
              <span class="hidden md:inline-block">MORE <i class="fas fa-caret-down"></i></span>
              <span class="inline-block md:hidden"><i class="fas fa-bars"></i></span>
            </MenuButton>
            <transition enter-active-class="transition duration-100 ease-out" enter-from-class="transform scale-95 opacity-0" enter-to-class="transform scale-100 opacity-100" leave-active-class="transition duration-75 ease-in" leave-from-class="transform scale-100 opacity-100" leave-to-class="transform scale-95 opacity-0">
              <MenuItems class="absolute uppercase text-sm right-2 w-48 mt-1 origin-top-right bg-white divide-y divide-gray-100 border-2 border-gray-200 rounded-lg ring-1 ring-black ring-opacity-5 focus:outline-none py-2">
                <MenuItem><router-link :to="{name:'News'}" :class="$route.name=='News'?'bg-opacity-80 hover:bg-opacity-90 text-white':'bg-opacity-0 hover:bg-opacity-10 text-gray-600'" class="block no-underline hover:no-underline px-3 py-2 bg-primary-dark text-right">News &amp; Alerts</router-link></MenuItem>
                <MenuItem><router-link :to="{name:'Market'}" :class="$route.name=='Market'?'bg-opacity-80 hover:bg-opacity-90 text-white':'bg-opacity-0 hover:bg-opacity-10 text-gray-600'" class="block no-underline hover:no-underline px-3 py-2 bg-primary-dark text-right">Market Cap Switch</router-link></MenuItem>
                <MenuItem><router-link :to="{name:'BurnGoals'}" :class="$route.name=='BurnGoals'?'bg-opacity-80 hover:bg-opacity-90 text-white':'bg-opacity-0 hover:bg-opacity-10 text-gray-600'" class="block no-underline hover:no-underline px-3 py-2 bg-primary-dark text-right">Big Burn Out</router-link></MenuItem>
                <MenuItem><router-link :to="{name:'Accounts'}" :class="$route.name=='Accounts'?'bg-opacity-80 hover:bg-opacity-90 text-white':'bg-opacity-0 hover:bg-opacity-10 text-gray-600'" class="block no-underline hover:no-underline px-3 py-2 bg-primary-dark text-right">Famous Accounts</router-link></MenuItem>
                <MenuItem><router-link :to="{name:'Links'}" :class="$route.name=='Links'?'bg-opacity-80 hover:bg-opacity-90 text-white':'bg-opacity-0 hover:bg-opacity-10 text-gray-600'" class="block no-underline hover:no-underline px-3 py-2 bg-primary-dark text-right">Useful Links</router-link></MenuItem>
                <!--<MenuItem><router-link :to="{name:'Airdrop'}" :class="$route.name=='Airdrop'?'bg-opacity-80 hover:bg-opacity-90 text-white':'bg-opacity-0 hover:bg-opacity-10 text-gray-600'" class="block no-underline hover:no-underline px-3 py-2 bg-primary-dark text-right">Retweet Airdrop</router-link></MenuItem>-->
                <MenuItem><router-link :to="{name:'About'}" :class="$route.name=='About'?'bg-opacity-80 hover:bg-opacity-90 text-white':'bg-opacity-0 hover:bg-opacity-10 text-gray-600'" class="block no-underline hover:no-underline px-3 py-2 bg-primary-dark text-right">About Us</router-link></MenuItem>
              </MenuItems>
            </transition>
          </Menu>

        </div>
      </div>
      <!--Nav-->

      <router-view/>

      <!--Footer-->
      <div class="pt-6 pb-20 h-full my-12">
        <div class="w-full text-md text-center fade-in text-gray-400">
          <div class="mb-4">
            <router-link tag="a" :to="{name:'Home'}" class="underline m-2">Home</router-link>
            <router-link tag="a" :to="{name:'News'}" class="underline m-2">News</router-link>
            <router-link tag="a" :to="{name:'Army'}" class="underline m-2">Army</router-link>
            <router-link tag="a" :to="{name:'Burn'}" class="underline m-2">Burn</router-link>
            <router-link tag="a" :to="{name:'Ecosystem'}" class="underline m-2">Eco</router-link>
            <router-link tag="a" :to="{name:'About'}" class="underline m-2">About</router-link>
          </div>
          <div class="mb-4">
            <span class="no-underline hover:no-underline m-2">ShibReport.com &copy; 2024</span>
            <!--<a class="underline ml-1" target="_blank" :href="this.$store.state.TWITTER_URL"><i class="fab fa-twitter"></i></a>-->
          </div>
          <div class="text-gray-300 text-sm">
            Donation {{this.$store.state.DONATION_ADDRESS}}
          </div>
        </div>
      </div>
      <!--Footer-->
          
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>

<script>
  import $ from 'jquery'
  import './assets/tailwind.css'
  import Spinner from './components/Spinner.vue'
  import OkModal from './components/OkModal.vue'
  import ConfirmModal from './components/ConfirmModal.vue'
  import ImageModal from './components/ImageModal.vue'
  import Notification from './components/Notification.vue'
  import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";

  export default {
    components: {
      Spinner, 
      OkModal, 
      ConfirmModal, 
      ImageModal,
      Notification, 
      Menu,
      MenuButton,
      MenuItems,
      MenuItem
    },

    async created() {
      console.log("ShibReport 2024")
    },

    async mounted() {
    },

    methods: {
    }

  }
</script>
