<template>
  <div>

    <!--CONTAINER-->
    <div class="container md:px-4 mx-auto mt-6 mb-6">
      <div class="p-6 md:p-10 sm:rounded-lg border-t-2 border-b-2 sm:border-2 card-bg">
        <div class="flex justify-between mb-6">
          <div class="font-bold text-gray-500 text-2xl md:text-3xl">Big Burn Out 🔥</div>
          <div v-if="burnGoals!=null" class="text-right text-xs text-gray-400 flex items-end pb-1">
            <div>updated {{$moment.utc(burnGoals.dt).fromNow()}}</div>
          </div>
        </div>
        <div v-if="burnGoals!=null" class="bg-blue-50 text-blue-500 p-4 rounded-xl mb-2 md:mb-4 text-center text-xl font-bold px-6">
          How many years would it take for SHIB to reach the price of X,
          with a market cap of $<span v-html="$common.abbreviateNumber(burnGoals.mc,1)"/>,
          if the current supply of <span v-html="$common.abbreviateNumber(burnGoals.total_supply,1)"/>
          was reduced by the 10-day average burn of <span v-html="$common.abbreviateNumber(burnGoals.avg_burn,1)"/> per day?
        </div>
        <div v-if="burnGoals!=null" class="grid grid-cols-1 sm:grid-cols-2 grid-flow-row gap-2 md:gap-4 mb-0">
          <div v-for="(n,i) of burnGoals.prices" v-bind:key="i">
              <div class="bg-gray-200 bg-opacity-50 p-4 rounded-xl h-full text-gray-500">
                <div class="flex justify-between">
                  <div class="flex text-base font-bold">
                    $<span v-html="$common.formatTokenLong($BN(n?.price),2)"/>
                  </div>
                  <span class="text-sm md:text-base" :class="n.duration=='DONE'?'text-green-500':''">
                    {{$common.numberCommas(n.duration)}}
                  </span>
                </div>
              </div>
          </div>
        </div>
        <div class="text-right text-xs text-gray-400 mt-4">* this assumes that market cap never changes, the page automatically updates!</div>
        <div class="text-center text-sm mt-8">
          <router-link :to="{name:'Links'}"><span class="rounded-full text-xs text-white bg-primary px-3 py-1">how can you contribute to more burning?</span></router-link>
        </div>
        <div class="text-center text-sm mt-4">
          <router-link :to="{name:'Market'}"><span class="rounded-full text-xs text-white bg-primary px-3 py-1">what if SHIB had Bitcoin's market cap?</span></router-link>
        </div>
      </div>
    </div>
    <!--CONTAINER-->


  </div>
</template>


<style lang="scss" scoped>
</style>

<script>
  import { ref } from 'vue'
  import IdleReloadMixin from '../mixins/IdleReloadMixin'
  import ServerMixin from '../mixins/ServerMixin'

  const REFRESH_SECS = 60 * 60

  export default {
    mixins: [IdleReloadMixin,ServerMixin],

    setup() {
      const burnGoals = ref()
      return {
        burnGoals
      }
    },

    created() {
      document.title = "ShibReport - Shib Burn Out"
    },

    async mounted() {
      await this.refreshData()
      this.idleReloadStart(REFRESH_SECS,this.refreshData)
    },

    async unmounted() {
      this.idleReloadStop()
    },
    
    methods: {

      async refreshData() {
        console.log("refreshData")
        this.$emitter.emit('spinner-on')

        this.burnGoals = await this.getBurnGoals()
        //console.log(JSON.stringify(this.burnGoals))

        this.$emitter.emit('spinner-off')
        this.idleReloadStart(REFRESH_SECS,this.refreshData)
      } 

    }

  }
</script>
