<template>
  <div>


    <!--OVERVIEW-->
    <div class="container md:px-4 mx-auto mt-6 mb-6">
      <div class="p-6 md:p-10 sm:rounded-lg border-t-2 border-b-2 sm:border-2 card-bg">

        <div class="flex justify-between mb-6">
          <div class="font-bold text-gray-500 text-2xl md:text-3xl">Shib Army</div>
          <span v-if="report!=null" class="text-xs text-gray-400 text-right">
            all holders = <b>{{$common.numberCommas(report.holders)}}</b><br/>
            updated {{$moment(report7.end).fromNow()}}
          </span>
        </div>

        <div class="text-base text-gray-600 mb-2">

          <!-- RANKS -->
          <div v-if="report!=null && rankTotal!=null" class="mb-10">
            <div class="bg-blue-50 text-blue-500 p-4 rounded-xl mb-4 text-center text-xl font-bold flex justify-between px-6">
              <div>Ranking</div>
              <div>{{$common.numberCommas(rankTotal)}} Troops</div>
            </div>
            <div class="grid grid-cols-2 sm:grid-cols-3 grid-flow-row gap-2 md:gap-4 mb-4">
              <div v-for="n of $store.state.RANKS" v-bind:key="n.id">
                <div class="bg-gray-200 bg-opacity-50 p-4 rounded-xl h-full">
                  <div class="mb-2">
                    <img class="h-24 w-24 rounded-full align-middle border-4 border-red-400 border-opacity-30 mx-auto mb-4 md:mb-0" :src="`/images/ranks/${n.id}.png`"/>
                  </div>
                  <div class="text-center text-gray-500">
                    <span class="font-bold text-lg">{{$common.numberCommas(report['rank_'+n.id])}}</span>
                    
                    <br class="md:hidden"/><span class="hidden md:inline">&nbsp;</span>

                    <span class="font-medium text-md md:font-bold">{{n.name}}</span><br/>
                    <span class="text-sm"><span class="hidden lg:inline">Holding&nbsp;</span>{{n.desc}}</span>
                    <div class="mt-2 mb-2">
                      <span class="rounded-full text-xs text-gray-600 bg-yellow-200 px-2 py-1"><span v-html="$common.formatPercentHtml($BN(report['rank_'+n.id]),$BN(rankTotal),3)"></span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-right text-xs text-gray-400">* holder is anyone holding >=1 token, percent shown is percent of total count</div>
          </div>
          <!-- RANKS -->

          <!-- HANDS -->
          <div v-if="report!=null && rankTotal!=null" class="mb-2">
            <div class="bg-blue-50 text-blue-500 py-4 rounded-xl mb-4 text-lg sm:text-xl font-bold flex justify-between px-4 sm:px-6">
              <div>Hand Strength</div>
              <div>{{$common.numberCommas(rankTotal)}} Holders</div>
            </div>
            <div class="grid grid-cols-2 grid-flow-row gap-2 md:gap-4 mb-4">
              <div v-for="n of $store.state.HANDS" v-bind:key="n.id">
                <div class="bg-gray-200 bg-opacity-50 p-4 rounded-xl h-full">
                  <div class="mb-2">
                    <img class="h-24 w-24 rounded-full align-middle border-4 border-gray-400 border-opacity-30 mx-auto mb-4 md:mb-0" :src="`/images/hands/${n.id}.png`"/>
                  </div>
                  <div class="text-center text-gray-500">
                    <span class="font-bold text-lg">{{$common.numberCommas(report['hand_'+n.id])}}</span>

                    <br class="md:hidden"/><span class="hidden md:inline">&nbsp;</span>

                    <span class="font-medium text-md md:font-bold">{{n.name}}</span><br/>
                    <span class="text-sm">{{n.desc}}</span>
                    <div class="mt-2 mb-2">
                      <span class="rounded-full text-xs text-gray-600 bg-yellow-200 px-2 py-1"><span v-html="$common.formatPercentHtml($BN(report['hand_'+n.id]),$BN(handTotal),1)"></span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-right text-xs text-gray-400">* holder is anyone holding >=1 token, hand is based on all-time tokens bought versus sold, percent shown is percent of total count</div>
          </div>
          <!-- HANDS -->

        </div>
      </div>
    </div>
    <!--OVERVIEW-->


    <!--HOLDERS-->
    <div class="container md:px-4 mx-auto mt-6 mb-6">
      <div class="p-6 md:p-10 sm:rounded-lg border-t-2 border-b-2 sm:border-2 card-bg">

        <div class="flex justify-between mb-6">
          <div class="font-bold text-gray-500 text-2xl md:text-3xl">Shib Holders</div>
          <span v-if="report!=null" class="text-xs text-gray-400 text-right flex items-end pb-1">
            <div>updated {{$moment(report7.end).fromNow()}}</div>
          </span>
        </div>

        <div class="bg-blue-50 text-blue-500 py-4 rounded-xl mb-4 text-lg sm:text-xl font-bold flex justify-between px-4 sm:px-6">
          <div>All Holders</div>
          <div v-if="report!=null">{{$common.numberCommas(report.holders)}} Holders</div>
        </div>

        <div class="text-base text-gray-600 mb-2">

          <!-- HOLDER CHART -->
          <div v-if="chart1Data!=null" class="text-base text-gray-600 mb-6">
            <div class="text-base text-gray-600 mb-2 lg:flex justify-between">
              <div class="w-full mb-6 lg:mb-0">
                <vue3-chart-js ref="chart1" :id="chart1Data.id" :type="chart1Data.type" :data="chart1Data.data" :options="chart1Data.options"/>
              </div>
            </div>
          </div>
          <!-- HOLDER CHART -->

          <!-- TOP HOLDERS -->
          <div> 
            <div class="block w-full overflow-x-auto text-gray-600 border-2 border-gray-200 border-opacity-50 rounded-lg">
              <table class="items-center bg-transparent w-full border-collapse">
                <thead>
                  <tr>
                    <th class="px-5 bg-blue-50 text-blue-500 align-middle border border-solid border-blue-100 py-3 text-xs uppercase border-l-0 border-r-0 border-t-0 whitespace-nowrap font-semibold text-left" width="60%">
                      Top {{$store.state.TOP_LIST_SIZE}} Holders <i class="fas fa-trophy"></i>
                    </th>
                    <th class="px-5 bg-blue-50 text-blue-500 align-middle border border-solid border-blue-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                      Last Move
                    </th>
                    <th class="px-5 bg-blue-50 text-blue-500 align-middle border border-solid border-blue-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-right">
                      Hold
                    </th>
                    <th class="px-5 bg-blue-50 text-blue-500 align-middle border border-solid border-blue-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-right">
                      %
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(n,i) of topHolders" v-bind:key="i">
                    <td class="border-t-0 px-5 align-middle border-l-0 border-r-0 whitespace-nowrap p-3 text-left text-sm">
                      <span v-if="n.name!=null" :title="n.address" class="font-bold">{{n.name}}</span>
                      <span v-else v-html="$common.addressHtml(n.address)"></span>
                    </td>
                    <td class="border-t-0 px-5 align-center border-l-0 border-r-0 whitespace-nowrap p-3 text-sm">
                      {{$moment.utc(n.dt).fromNow()}}
                    </td>
                    <td class="border-t-0 px-5 align-middle border-l-0 border-r-0 whitespace-nowrap p-3 text-right text-sm">
                      <span v-html="$common.abbreviateNumberHtml(parseFloat(n.total),1)"/>
                    </td>
                    <td class="border-t-0 px-5 align-middle border-l-0 border-r-0 whitespace-nowrap p-3 text-right text-sm">
                      <span v-html="$common.formatPercentHtml($BN(n.total),$BN(topHoldersTotal),2)"/>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="text-right text-xs text-gray-400 mt-4">* percent is % of all tokens held</div>
          </div>
          <!-- TOP HOLDERS -->

          <div class="text-center text-sm mt-6">
            <router-link :to="{name:'Accounts'}"><span class="rounded-full text-xs text-white bg-primary px-3 py-1">who are some famous holders?</span></router-link>
          </div>

        </div>
      </div>
    </div>
    <!--HOLDERS-->


    <!--FAQ-->
    <div class="container md:px-4 mx-auto mt-6 mb-6">
      <div class="p-6 md:p-10 sm:rounded-lg border-t-2 border-b-2 sm:border-2 card-bg">
        <div class="flex justify-between">
          <div class="font-bold text-gray-500 text-2xl md:text-3xl">Army FAQ</div>
        </div>

        <div class="text-base mt-4">
          <div class="my-5 border-2 border-opacity-50 p-4 md:p-6 rounded-lg text-md">
            <div class="font-bold mb-2 text-gray-500">How do I join this fine collection of misfits?</div>
            <div class="text-gray-600">
              To join the Shib Army just buy $SHIB at your nearest CEX (<a href="https://www.coinbase.com" target="_blank" class="text-blue-500 underline">Coinbase</a>, <a href="https://www.binance.us" target="_blank" class="text-blue-500 underline">Binance</a>) or a DEX (<a href="https://app.uniswap.org/#/swap" target="_blank" class="text-blue-500 underline">Uniswap</a>, <a href="https://shibaswap.com/#/swap" target="_blank" class="text-blue-500 underline">Shibaswap</a>).
              Depending on the number of tokens you buy, you are assigned to one of the ranks above.
              When you join your mission to hold on for dear life (HODL) until the amount it goes up is enough to change your life significantly.    
            </div>
          </div>
        </div>

        <div class="text-base mt-4">
          <div class="my-5 border-2 border-opacity-50 p-4 md:p-6 rounded-lg text-md">
            <div class="font-bold mb-2 text-gray-500">What's this army and hand strength all about?</div>
            <div class="text-gray-600">
              This listing is for entertainment purposes only.  It just shows where the Shib Army stand at a moment in time.
              The rankings were based on and inspired by a modified version of a $SHIB Army Ranks pic thanks to <a href="https://twitter.com/CoinJester" target="_blank" class="text-blue-500 underline">@CoinJester</a>. 
              We added Overlord because the distance between General and Imperator were a bit far.
            </div>
          </div>
        </div>

        <div class="text-base mt-4">
          <div class="my-5 border-2 border-opacity-50 p-4 md:p-6 rounded-lg text-md">
            <div class="font-bold mb-2 text-gray-500">What's the difference between an "army" and "holder"?</div>
            <div class="text-gray-600">
              The counts for "army" is anyone holding 1 or more SHIB tokens, they are propbably purposely holding.  
              The "holder" count is any address holding any SHIB token amount greater than zero, which could be remnant holders who may not intend to hold.
              The "holder" count is still not accurate to <a href="https://etherscan.io/token/0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce" target="_blank" class="underline text-blue-500">etherscan</a>, but its close, we will investigate why its not exact.
            </div>
          </div>
        </div>

      </div>
    </div>
    <!--FAQ-->


  </div>
</template>


<style lang="scss" scoped>
</style>


<script>
  import { ref } from 'vue'
  import IdleReloadMixin from '../mixins/IdleReloadMixin'
  import ServerMixin from '../mixins/ServerMixin'
  import Vue3ChartJs from "@j-t-mcc/vue3-chartjs"
  import ChartHelper from '../mixins/ChartHelper'
  import dataLabels from "chartjs-plugin-datalabels"
  
  Vue3ChartJs.registerGlobalPlugins([dataLabels])

  const DAYS = 7
  const REFRESH_SECS = 60

  export default {
    components: {Vue3ChartJs},

    mixins: [IdleReloadMixin,ServerMixin,ChartHelper],

    setup() {
      const chart1 = ref(null)
      const chart1Data = ref(null)
      const report = ref()
      const report7 = ref()
      const topHolders = ref()
      const topHoldersTotal = ref(0)
      const handTotal = ref(null)
      const rankTotal = ref(null)

      return {
        chart1,chart1Data,
        report,report7,
        topHolders,
        topHoldersTotal,
        rankTotal,
        handTotal
      }
    },

    created() {
      document.title = "ShibReport - ShibArmy Ranks"
    },

    async mounted() {
      await this.refreshData()
      this.idleReloadStart(REFRESH_SECS,this.refreshData)
    },

    async unmounted() {
      this.idleReloadStop()
    },
    
    methods: {

      async refreshData() {
        console.log("refreshData")
        this.$emitter.emit('spinner-on')

        this.report7 = await this.getReportRange('range','7') //Get last 7 days
        this.report = this.report7.data[0] //Current

        let holderSort = [...this.report7.data].sort((a,b)=>b.holders-a.holders)
        holderSort.forEach((n,i)=>n.sort=i)

        this.topHolders = await this.getTopHolders()
        this.topHoldersTotal = 0
        this.topHolders.forEach(n=>this.topHoldersTotal+=parseFloat(n.total))

        this.handTotal = this.report.hand_1 + this.report.hand_2 + this.report.hand_3 + this.report.hand_4
        this.rankTotal = this.report.rank_1 + this.report.rank_2 + this.report.rank_3 + this.report.rank_4 + this.report.rank_5 + this.report.rank_6 + this.report.rank_7 + this.report.rank_8 + this.report.rank_9

        this.chart1Data = {
          id: "chart1",
          type: "bar",
          data: {
            labels: this.report7.data.map(n=>this.$moment(n.dt).format('M/D')),
            datasets: [{
              backgroundColor: this.report7.data.map((n,i)=>this.getChartColorStringAlpha(4,1-(n.sort/7))),
              data: this.report7.data.map(n=>n.holders),
              fill: false,
              tension: 0.2,
              pointRadius: 6
            }]
          },
          options: {
            borderWidth: 2,
            borderColor: "#e8e8e8",
            aspectRatio: 2,
            scales: {
              x: {
                offset:true,
                reverse:true
              },
              y: {
                beginAtZero: false,
                offset:true,
                ticks: {
                  callback: (value, index, values) => this.$common.numberCommas(value)
                }
              }
            },
            plugins: {
              title: {
                text: 'All Holders Last 7 Days',
                align: "bottom",
                position: "bottom",
                display: true
              },
              legend: {
                display:false
              },
              datalabels: {
                color: "#a0a0a0",
                anchor: "end",
                align: "end",
                font: {weight: 'bold'},
                display: 'auto',
                formatter: (value, ctx) => this.$common.numberCommas(value)
              }
            }
          }
        }

        this.$emitter.emit('spinner-off')
        this.idleReloadStart(REFRESH_SECS,this.refreshData)
      }
      
    }
  }
</script>
