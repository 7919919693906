import {reactive} from 'vue'

const state = reactive({
  CONTACT_EMAIL: "shibreport@gmail.com",
  TWITTER_URL: "https://twitter.com/shibreportcom",
  DISCORD_URL: "https://discord.gg/DvGvCYuxMN",
  WEBULL_REFERER_URL: "https://a.webull.com/wfFLEmJgv31t3CnOOc",
  COINBASE_REFERER_URL: "https://www.coinbase.com/join/suriya_w",
  DONATION_ADDRESS: "0x2DC8066E031b1c81003002368c8434499DF77F79",
  BURN_ADDRESS: "0xdead000000000000000042069420694206942069",
  ONE_QUAD: 1000000000000000,
  TOP_LIST_SIZE: 15,
  RECENT_LIST_SIZE: 100,

  RANKS: [
    {id:1,name:"Privates",desc:"1 - 99K"},
    {id:2,name:"Sergeants",desc:"100K - 499K"},
    {id:3,name:"Gunnies",desc:"500K - 999K"},
    {id:4,name:"Lieutenants",desc:"1M - 49M"},
    {id:5,name:"Captains",desc:"50M - 99M"},
    {id:6,name:"Commanders",desc:"100M - 499M"},
    {id:7,name:"Generals",desc:"500M - 999M"},
    {id:8,name:"Imperators",desc:"1B - 999B"},
    {id:9,name:"Overlords",desc:"1 Trillion+"},
  ],

  HANDS: [
    {id:1,name:"Diamond Hands",desc:"Never Sold"},
    {id:2,name:"Iron Hands",desc:"Sold Some"},
    {id:3,name:"Cabbage Hands",desc:"Wavering"},
    {id:4,name:"Paper Hands",desc:"Sell Out"},
  ],

  TOKENS: [
    {id:1,short:"Shib",name:"Shiba Inu",token:"shiba-inu",desc:"Shib is the main token of the ecosystem"},
    {id:2,short:"Leash",name:"Leash",token:"leash",desc:"Leash is meant to compete with Bitcoin"},
    {id:3,short:"Bone",name:"Bone",token:"bone-shibaswap",desc:"Bone is the reward given by Shibaswap"},
    {id:4,short:"K9",name:"K9 Finance",token:"k9-finance-dao",desc:"K9 Finance DAO"},
  ],

  MILESTONES: [
    {id:1,short:"penny",name:"Penny",price:0.01},
    {id:2,short:"nickel",name:"Nickel",price:0.05},
    {id:3,short:"dime",name:"Dime",price:0.10},
    {id:4,short:"dollar",name:"Dollar",price:1.00},
  ],

  OFFICIAL_LINKS: [
    {
      icon: "🔥",
      id: 1,
      name: "Shibarium Website",
      desc: "Official Shibarium Technology website.",
      url: "https://www.shibariumtech.com/",
      twitter_url: "https://twitter.com/shibariumtech",
      discord_url: "https://t.co/ISEnbfIo3b"
    },
    {
      id: 2,
      name: "Shiba Token Official",
      desc: "Official Shibatoken homepage that started it all.",
      url: "https://shibatoken.com/",
      medium_url: "https://blog.shibaswap.com/",
      twitter_url: "https://twitter.com/Shibtoken",
      telegram_url: "https://t.me/ShibaInu_Dogecoinkiller",
      discord_url: "discord.com"
    },
    {
      id: 3,
      name: "ShibSwap DEX",
      desc: "Decentralized exchange for ERC20 tokens, use it!",
      url: "https://shibaswap.com/",
      medium_url: null,
      twitter_url: "https://twitter.com/ShibaSwapDEX",
      telegram_url: null,
      discord_url: null
    },
    {
      id: 4,
      name: "Shiboshis NFT",
      desc: "Shiba Inu NFT for trading and burning through renaming.",
      url: "https://shiboshis.shibaswap.com/#/",
      medium_url: null,
      twitter_url: null,
      telegram_url: null,
      discord_url: null
    },
    {
      id: 5,
      name: "Ryoshi Research",
      desc: "Founder of SHIBA and the ideas behind SHIBA.",
      url: null,
      medium_url: "https://allhailtheshiba.medium.com",
      twitter_url: "https://twitter.com/RyoshiResearch",
      telegram_url: null,
      discord_url: null
    },
    {
      id: 6,
      name: "Shytoshi Kusama",
      desc: "Shibaswap Dev and Project Manager lead for ShibaSwap.",
      url: null,
      medium_url: null,
      twitter_url: "https://twitter.com/ShytoshiKusama",
      telegram_url: null,
      discord_url: null
    },
    {
      id: 6,
      name: "Shib Informer",
      desc: "Head detective of the Shibtoken. Posts media, news, and info pertaining to SHIBA.",
      url: null,
      medium_url: null,
      twitter_url: "https://twitter.com/ShibInformer",
      telegram_url: null,
      discord_url: null
    },
  ],


  BURN_LINKS: [
    

    {
      id: 7,
      icon: "🔥",
      name: "ShibBurn.com",
      desc: "Simple site displaying SHIB burns.",
      url: "https://www.shibburn.com/",
      medium_url: null,
      twitter_url: "https://www.twitter.com/shibburn",
      telegram_url: null,
      discord_url: null
    },

    // {
    //   id: 7,
    //   icon: "🔥",
    //   name: "Bricks Buster by Travis Johnson",
    //   desc: "Play IOS and Android games while burning $SHIB for free.",
    //   url: "https://apps.apple.com/us/app/bricks-buster/id1523140876",
    //   medium_url: null,
    //   twitter_url: "https://twitter.com/317_key",
    //   telegram_url: null,
    //   discord_url: null
    // },
    // {
    //   id: 8,
    //   icon: "🔥",
    //   name: "Shib Burner by Bigger Entertainment",
    //   desc: "Listen to some beats while burning $SHIB tokens by Bigger Entertainment.",
    //   url: "https://www.biggerentertainment.com/shib",
    //   medium_url: null,
    //   twitter_url: "https://twitter.com/iamstevencooper",
    //   telegram_url: null,
    //   discord_url: null
    // },
  ],


  server_url: "https://www.shibreport.com",
  //server_url: "http://localhost:3000",
  api_data: {}
});

const methods = {

  getFirstLink(link) {
    return link.url || link.medium_url || link.twitter_url || link.telegram_url || link.discord_url;
  }

}

export default {
  state,
  methods
}
