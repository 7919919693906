<template>
  <div>

    <!--TOKEN SUMMARY-->
    <div class="container md:px-4 mx-auto mt-6 mb-6">
      <div class="p-6 md:p-10 sm:rounded-lg border-t-2 border-b-2 sm:border-2 card-bg">
        <div class="flex justify-between mb-6">
          <div class="font-bold text-gray-500 text-2xl md:text-3xl">Shib Ecosystem</div>
          <div v-if="tokens!=null" class="text-right text-xs text-gray-400 flex items-end pb-1">
            <div>updated {{$moment.utc(tokens[0].current.created).fromNow()}}</div>
          </div>
        </div>
        <div v-if="tokens!=null" class="grid grid-cols-1 sm:grid-cols-2 grid-flow-row gap-2 md:gap-4 mb-0">
          <div v-for="token of tokens" v-bind:key="token.store.token">
            <token-quote :token="token"/>
          </div>
        </div>
      </div>
    </div>
    <!--TOKEN SUMMARY-->

    <!--TOKEN-->
    <div v-for="token of tokens" v-bind:key="token.store.cg_id" class="container md:px-4 mx-auto mt-6 mb-6">
      <div class="p-6 md:p-10 sm:rounded-lg border-t-2 border-b-2 sm:border-2 card-bg">

        <div class="flex justify-between mb-4">
          <div class="flex">
            <img :src="'images/tokens/'+token.store.token+'.png'" class="h-6 sm:h-7 md:h-9 mr-1 md:mr-2"/>
            <div class="font-bold text-gray-500 text-md sm:text-xl md:text-2xl">
              <span class="hidden sm:inline-block">{{token.store.name}}</span>
              <span class="inline-block sm:hidden">{{token.store.short}}</span>
              <span :class="token.change<0?'text-red-500':'text-green-500'" class="ml-2">
                <span v-if="token.change>0">+</span>{{token.change?.toFixed(2)}}%
              </span>
            </div>
          </div>
          <div v-if="token!=null" class="text-right">
            <div class="text-sm md:text-lg text-gray-600">
              $<span v-html="$common.formatBalanceHtml($BN(token.current?.price))"></span>
            </div>
            <div class="text-xs text-gray-400">updated {{$moment.utc(token.current.created).fromNow()}}</div>
          </div>
        </div>

        <div v-if="token!=null" class="text-base text-gray-600 mb-4">
          <div class="text-base text-gray-600 mb-2 lg:flex justify-between">
            <div class="w-full mb-6 lg:mb-0">
              <vue3-chart-js :ref="token.chart.el" :id="token.chart.id" :type="token.chart.type" :data="token.chart.data" :options="token.chart.options"/>
            </div>
          </div>
        </div>
        
        <div class="text-right text-xs text-gray-400">* {{token.store.desc}}, change is 24H</div>
      </div>
    </div>
    <!--TOKEN-->


  </div>
</template>


<style lang="scss" scoped>
</style>


<script>
  import { ref } from 'vue'
  import IdleReloadMixin from '../mixins/IdleReloadMixin'
  import ServerMixin from '../mixins/ServerMixin'
  import Vue3ChartJs from "@j-t-mcc/vue3-chartjs"
  import ChartHelper from '../mixins/ChartHelper'
  import dataLabels from "chartjs-plugin-datalabels"
  import TokenQuote from '../components/TokenQuote'

  Vue3ChartJs.registerGlobalPlugins([dataLabels])

  const DAYS = 7
  const REFRESH_SECS = 60

  export default {
    components: {Vue3ChartJs,TokenQuote},

    mixins: [IdleReloadMixin,ServerMixin,ChartHelper],

    setup() {
      const tokens = ref()
      return {
        tokens
      }
    },

    created() {
      document.title = "ShibReport - Shib Ecosystem $SHIB $BONE $LEASH $KNINE"
    },

    async mounted() {
      await this.refreshData()
      this.idleReloadStart(REFRESH_SECS,this.refreshData)
    },

    async unmounted() {
      this.idleReloadStop()
    },
    
    methods: {

      async refreshData() {
        console.log("refreshData")
        this.$emitter.emit('spinner-on')

        let tokensArr = []
        for(let n of this.$store.state.TOKENS) {
          let data = (await this.getPriceRange(n.token,'range',DAYS)).data
          let change = data.length<=1 ? 0 : ((data[data.length-1].price-data[data.length-2]?.price)/data[data.length-1]?.price) * 100
          if(!change) change = 0

          tokensArr.push({
            store: n,
            data,
            el: null,
            last: data[data.length-2],
            current: data[data.length-1],
            change,
            chart: {
              type: "line",
              data: {
                labels: data.map(n=>this.$moment(n.dt).format('M/D')),
                datasets: [{
                  backgroundColor: data.map((n,i)=>this.getChartColorStringAlpha(change<0?2:4,1) ),
                  data: data.map(n=>n?.price),
                  fill: false,
                  tension: 0.2,
                  pointRadius: 6
                }]
              },
              options: {
                borderWidth: 3,
                borderColor: this.getChartColorStringAlpha(change<0?2:4,0.25),
                aspectRatio: 2,
                scales: {
                  x: {
                    offset:true,
                    reverse:false
                  },
                  y: {
                    beginAtZero: false,
                    offset:true,
                    ticks: {
                      callback: (value, index, values) => this.$common.formatToken(this.$BN(value))
                    }
                  }
                },
                plugins: {
                  title: {
                    text: 'Price Last 7 Days',
                    align: "bottom",
                    position: "top",
                    display: false
                  },
                  legend: {
                    display:false
                  },
                  datalabels: {
                    color: "#a0a0a0",
                    anchor: "end",
                    align: "end",
                    font: {weight: 'bold'},
                    display: 'auto',
                    formatter: (value, ctx) => this.$common.formatToken(this.$BN(value))
                  }
                }
              }
            }
          });
        }
        this.tokens = tokensArr
        this.token = tokensArr[0]

        this.$emitter.emit('spinner-off')
        this.idleReloadStart(REFRESH_SECS,this.refreshData)
      } 

    }

  }
</script>
