import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'

const app = createApp(App)

// Font awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas);
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fab);
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(far);
import { dom } from "@fortawesome/fontawesome-svg-core";
dom.watch();
app.component("font-awesome-icon", FontAwesomeIcon);

// Localization
import i18n from './localization'
app.use(i18n)

// Big numbers
import Big from 'big.js' // https://www.npmjs.com/package/big.js/v/5.2.1
app.config.globalProperties.$BN = Big

// Moment
import moment from 'moment-timezone' // https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/
app.config.globalProperties.$moment = moment

// Numeral for formatting
import numeral from 'numeral' // http://numeraljs.com/
app.config.globalProperties.$numeral = numeral

// Event bus
import mitt from 'mitt';
app.config.globalProperties.$emitter = mitt()

// Axios
import axios from 'axios'
app.config.globalProperties.$axios = axios

// Common 
import common from './common'
app.config.globalProperties.$common = common

// Store 
import store from './store'
app.config.globalProperties.$store = store

// Countdown
import countdown from 'countdown' // http://countdownjs.org/demo.html
app.config.globalProperties.$countdown = countdown

/*
// Web3
import wallet from './wallet';
app.config.globalProperties.$wallet = wallet
*/

// Router
import router from './router'
app.use(router)

app.mount('#app')

export default app